
class InstallmentCell extends UITableViewCell
{
    private docNumberLabel:MUILabel = null;    
    private dateLabel:MUILabel = null;
    private conceptLabel:MUILabel = null;
    private referenceLabel:MUILabel = null;
    private amountLabel:MUILabel = null;
    private paymentMethodButton:MUIButton = null;
    private paymentMethodLabel:MUILbel = null;
    private paymentStatusLabel:MUILabel = null;
    private optionsButton:MUIButton = null;

    awakeFromHTML(): void {
        this.docNumberLabel = MUIOutlet( this, "doc-number-lbl", "MUILabel" );
        this.dateLabel = MUIOutlet( this, "date-lbl", "MUILabel" );
        this.conceptLabel = MUIOutlet( this, "concept-lbl", "MUILabel" );
        this.referenceLabel = MUIOutlet( this, "concept-sub-lbl", "MUILabel" );
        this.amountLabel = MUIOutlet( this, "amount-lbl", "MUILabel" );
        this.paymentMethodButton = MUIOutlet( this, "pay-method-btn", "MUIButton" );
        this.paymentMethodLabel = MUIOutlet( this, "pay-method-lbl", "MUILabel" );
        this.paymentMethodButton.setAction( this, function(this:InstallmentCell) {
            AppHelper.sharedInstance().showSelectPaymethodViewControllerFromView( this.paymentMethodButton, this.installment.paymentMethod, this, function(this:InstallmentCell, controller:any, object:PayMethod){
                this.installment.paymentMethod = object;
                this.paymentMethodButton.title = object?.name;
            });
        });
        this.paymentStatusLabel = MUIOutlet( this, "payment-status-lbl", "MUILabel" );
        this.optionsButton = MUIOutlet( this, "options-btn", "MUIButton" );
        this.optionsButton.setAction(this, this.showOptions);
    }

    private add_style(style:string) {
        MUICoreLayerAddStyle( this.docNumberLabel.layer, style );
        MUICoreLayerAddStyle( this.dateLabel.layer, style );
        MUICoreLayerAddStyle( this.conceptLabel.layer, style );
        MUICoreLayerAddStyle( this.referenceLabel.layer, style );
        MUICoreLayerAddStyle( this.amountLabel.layer, style );
        MUICoreLayerAddStyle( this.paymentMethodLabel.layer, style );
        MUICoreLayerAddStyle( this.paymentStatusLabel.layer, style );
    }

    private installment:PaymentInstallment = null;
    set item( item: PaymentInstallment ) {
        this.installment = item;

        this.docNumberLabel.text = (item instanceof LegalEntityPaymentInstallment) ? item.documentNumber : null;
        
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        this.dateLabel.text = ad.dateFormatter.stringFromDate( item.date );
        this.conceptLabel.text = item.concept;
        this.referenceLabel.text = item.operationReference != null ? item.operationReference : null;
        this.amountLabel.text = ad.currencyFormatter.stringFromNumber( item.amount );
        this.paymentStatusLabel.text = item.statusString();

        switch(item.status) {

            case PaymentInstallmentStatus.invalidAmount:
                this.paymentMethodLabel.text = item.paymentMethod?.name;
                this.paymentMethodButton.hidden = true;
                this.add_style("alert");
            break;    

            case PaymentInstallmentStatus.pending:
                this.paymentMethodButton.title = item.paymentMethod?.name;
                this.paymentMethodLabel.hidden = true;
            break;
    
            case PaymentInstallmentStatus.locked:
                this.paymentMethodLabel.text = item.paymentMethod?.name;
                this.paymentMethodButton.hidden = true;
                this.add_style( "warning" );
            break;

            case PaymentInstallmentStatus.paid:
            case PaymentInstallmentStatus.compensated:
                this.paymentMethodLabel.text = item.paymentMethod?.name;
                this.paymentMethodButton.hidden = true;
                this.add_style( "active" );
                this.paymentStatusLabel.text = ad.dateFormatter.stringFromDate( item.paymentDate );
            break;

            case PaymentInstallmentStatus.returned:
                this.paymentMethodLabel.text = item.paymentMethod?.name;
                this.paymentMethodButton.hidden = true;
                this.add_style( "alert" );
            break;

            case PaymentInstallmentStatus.void:
                this.paymentMethodLabel.text = item.paymentMethod?.name;
                this.paymentMethodButton.hidden = true;
                this.docNumberLabel.text = this.strike_through( this.docNumberLabel.text );
                this.dateLabel.text = this.strike_through( this.dateLabel.text );
                this.conceptLabel.text = this.strike_through( this.conceptLabel.text );
                this.referenceLabel.text = this.strike_through( this.referenceLabel.text );
                this.amountLabel.text = this.strike_through( this.amountLabel.text );
                this.paymentMethodLabel.text = this.strike_through( this.paymentMethodLabel.text );
                this.paymentStatusLabel.text = this.strike_through( this.paymentStatusLabel.text );
            break;

            case PaymentInstallmentStatus.confirmedButNotPay:
                this.paymentMethodLabel.text = item.paymentMethod?.name;
                this.paymentMethodButton.hidden = true;
                this.add_style( "caution" );
            break;

            case PaymentInstallmentStatus.partialPayment:
                this.paymentMethodLabel.text = item.paymentMethod?.name;
                this.paymentMethodButton.hidden = true;
                this.add_style( "active" );
                this.paymentStatusLabel.text = ad.dateFormatter.stringFromDate( item.paymentDate );
            break;

            case PaymentInstallmentStatus.unpaid:
                this.paymentMethodLabel.text = item.paymentMethod?.name;
                this.paymentMethodButton.hidden = true;
                this.add_style( "alert" );
            break;
        }    
    }

    private strike_through(text:string) {
        return "<strike>" + text + "</strike>";
    }

    private showOptions(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString( "OPTIONS", "OPTIONS" ), MIOLocalizeString( "SELECT AN OPTION", "SELECT AN OPTION" ), MUIAlertViewStyle.Default );

        if ( MUIWebApplication.sharedInstance().delegate.selectedUser.isAdmin ) {

            avc.addAction( MUIAlertAction.alertActionWithTitle( "LINK WITH INVOICE", MUIAlertActionStyle.Default, this, function(this:InstallmentCell){
                this.showInvoice();
            } ));

            avc.addAction( MUIAlertAction.alertActionWithTitle( "UNLINK INVOICE", MUIAlertActionStyle.Default, this, function(this:InstallmentCell){
                this.unlinkInvoice();
            } ));

            avc.addAction( MUIAlertAction.alertActionWithTitle( "GENERATE INVOICE", MUIAlertActionStyle.Default, this, function(this:InstallmentCell){
                this.generateInvoice();
            } ));

            avc.addAction( MUIAlertAction.alertActionWithTitle( "UPDATE INVOICE", MUIAlertActionStyle.Default, this, function(this:InstallmentCell){
                this.updateInvoice();
            } ));

            avc.addAction( MUIAlertAction.alertActionWithTitle( "ASSIGN OPERATION ID", MUIAlertActionStyle.Default, this, function(this:InstallmentCell){
                this.showSelectOperationIDViewController();
            } ));

            avc.addAction( MUIAlertAction.alertActionWithTitle( "REMOVE OPERATION ID", MUIAlertActionStyle.Default, this, function(this:InstallmentCell){
                this.installment.operationReference = null;
            } ));

            // avc.addAction( MUIAlertAction.alertActionWithTitle( "DELETE", MUIAlertActionStyle.Destructive, this, function(this:InstallmentCell){
            //     DBHelper.deleteObjectFromMainContext( this.installment, true );
            // } ));
        }

        avc.addAction( MUIAlertAction.alertActionWithTitle( "MARK AS PENDING", MUIAlertActionStyle.Default, this, function(this:InstallmentCell){
            this.installment.status = PaymentInstallmentStatus.pending;
            DBHelper.saveMainContext();
        } ));

        if (this.installment.status == PaymentInstallmentStatus.pending) {

            avc.addAction( MUIAlertAction.alertActionWithTitle( "MARK AS UNPAID", MUIAlertActionStyle.Destructive, this, function(this:InstallmentCell){
                this.installment.status = PaymentInstallmentStatus.unpaid;
                DBHelper.saveMainContext();
            } ));

            avc.addAction( MUIAlertAction.alertActionWithTitle( "MARK AS CONFIRMED, NOT PAY", MUIAlertActionStyle.Default, this, function(this:InstallmentCell){
                this.installment.status = PaymentInstallmentStatus.confirmedButNotPay;
                DBHelper.saveMainContext();
            } ));    
        }
        
        avc.addAction( MUIAlertAction.alertActionWithTitle( "MARK AS RETURNED", MUIAlertActionStyle.Destructive, this, function(this:InstallmentCell){
            this.installment.status = PaymentInstallmentStatus.returned;
            DBHelper.saveMainContext();
        } ));

        avc.addAction( MUIAlertAction.alertActionWithTitle( "MARK AS LOCK", MUIAlertActionStyle.Default, this, function(this:InstallmentCell){
            this.installment.status = PaymentInstallmentStatus.locked;
            DBHelper.saveMainContext();
        } ));

        avc.addAction( MUIAlertAction.alertActionWithTitle( "MARK AS PAID", MUIAlertActionStyle.Default, this, function(this:InstallmentCell){
            this.showPaymentAmountAlert( PaymentInstallmentStatus.paid);
        } ));

        avc.addAction( MUIAlertAction.alertActionWithTitle( "MARK AS COMPENSATED", MUIAlertActionStyle.Default, this, function(this:InstallmentCell){
            this.showPaymentAmountAlert( PaymentInstallmentStatus.compensated);
        } ));

        avc.addAction( MUIAlertAction.alertActionWithTitle( "VOID INVOICE", MUIAlertActionStyle.Destructive, this, function(this:InstallmentCell){
            this.voidInvoice();
        } ));

        if (this.installment.status == PaymentInstallmentStatus.unpaid || this.installment.status == PaymentInstallmentStatus.returned) {
            avc.addAction( MUIAlertAction.alertActionWithTitle( "GENERATE RECLAIM INSTALLMENT", MUIAlertActionStyle.Default, this, function(this:InstallmentCell){
                this.showReclaimInstallmentAlert();
            } ));

            avc.addAction( MUIAlertAction.alertActionWithTitle( "CREATE RECLAIM INVOICE", MUIAlertActionStyle.Default, this, function(this:InstallmentCell){
                this.showReclaimInvoiceAlert();
            } ));
        }

        // TODO: Check for SEPA account and enable this option
        if (this.installment.status == PaymentInstallmentStatus.pending) {
            avc.addAction( MUIAlertAction.alertActionWithTitle( "GENERATE SEPA FILE", MUIAlertActionStyle.Default, this, function(this:InstallmentCell){
                this.showSEPAOptionsAlert();
            } ));

            avc.addAction( MUIAlertAction.alertActionWithTitle( "DELETE", MUIAlertActionStyle.Destructive, this, function(this:InstallmentCell){
                DBHelper.deleteObjectFromMainContext(this.installment, true);
            } ));
        }

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null ) );

        AppHelper.sharedInstance().presentViewController( avc, true );
    }

    private showInvoice(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle( MIOLocalizeString( "INVOICE", "INVOICE" ), MIOLocalizeString( "TYPE THE NUMBER OF THE INVOICE", "TYPE THE NUMBER OF THE INVOICE" ), MUIAlertViewStyle.Default );

        avc.addTextFieldWithConfigurationHandler( this, function(this:InstallmentCell, textField:MUITextField){
            textField.placeholderText = MIOLocalizeString( "DOCUMENT NUMBER", "DOCUMENT NUMBER" );
        });

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString( "OK", "OK" ), MUIAlertActionStyle.Default, this, function(this:InstallmentCell){
            this.linkWithInvoice( avc.textFields[0].text );
        } ));

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        AppHelper.sharedInstance().presentViewController( avc, true );
    }

    private linkWithInvoice(documentNumber:string) {
        DBHelper.queryObjectWithCompletion( "SalesInvoice", null, MIOPredicate.predicateWithFormat( "documentNumber == '" + documentNumber + "'" ), [], this, function(object:SalesInvoice) {

            if (object == null) {
                AppHelper.showErrorMessage(null, "ERROR", "COULDN'T FOUND ANY INVOICE");
                return;
            }
            
            if (this.installment instanceof LegalEntityPaymentInstallment) {
                this.installment.invoice = object;
                this.installment.documentNumber = object.documentNumber;
                this.docNumberLabel.text = object.documentNumber;
            }
        } );
    }

    private unlinkInvoice() {
        
        if (this.installment instanceof LegalEntityPaymentInstallment) {
            this.installment.invoice = null;
            this.installment.documentNumber = null;
        }

        this.docNumberLabel.text = null;
    }

    private generateInvoice() {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        ad.webService.createInvoiceFromInstallment( this.installment, (response, error) => {
            if (error != null ) {
                AppHelper.showErrorMessage(null, MIOLocalizeString("ERROR","ERROR"), error );
                return;
            }            
            
            this.docNumberLabel.text = response["number"];
            AppHelper.showInfoMessage(null, MIOLocalizeString("INFO", "INFO"), MIOLocalizeString("THE OPERATION WAS SUCCESSFULL", "THE OPERATION WAS SUCCESSFULL"));

            // let invoiceID = response["id"];
            // if ( invoiceID != null ) {                
            //     DBHelper.queryObjectFromMainContext( "SalesInvoice", MIOPredicate.predicateWithFormat( "identifier == " + invoiceID), [] );
            // }
            
        });
    }    

    private updateInvoice() {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        ad.webService.updateInvoiceFromInstallment( this.installment, (response, error) => {
            if (error != null ) {
                AppHelper.showErrorMessage(null, MIOLocalizeString( "ERROR", "ERROR" ), error );
                return;
            }            
            
            AppHelper.showInfoMessage(null, MIOLocalizeString("INFO", "INFO"), MIOLocalizeString("THE OPERATION WAS SUCCESSFULL", "THE OPERATION WAS SUCCESSFULL"));
        });
    }

    private showSelectOperationIDViewController() {
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString( "OPERATION", "OPERATION" ), MIOLocalizeString( "TYPE THE OPERATION ID", "TYPE THE OPERATION ID" ), MUIAlertViewStyle.Default );

        avc.addTextFieldWithConfigurationHandler( this, function(this:InstallmentCell, textField:MUITextField){
            textField.placeholderText = MIOLocalizeString( "OPERATION ID", "OPERATION ID" );
        });

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString( "OK", "OK" ), MUIAlertActionStyle.Default, this, function(this:InstallmentCell){
            this.assignOperationRef( avc.textFields[0].text.trim() );
        } ));

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        AppHelper.sharedInstance().presentViewController( avc, true );
    }

    private assignOperationRef( ref: string ) {
        if (ref.length == 0 ) return;

        this.installment.operationReference = ref;
        DBHelper.saveMainContext();
    }

    private showPaymentAmountAlert( status:PaymentInstallmentStatus ){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString( "INFO", "INFO" ), MIOLocalizeString( "INOUT AMOUNT", "TYPE THE OPERATION ID" ), MUIAlertViewStyle.Default );

        avc.addTextFieldWithConfigurationHandler( this, function(this:InstallmentCell, textField:MUITextField){
            textField.placeholderText = MIOLocalizeString( "PAYMENT DATE", "PAYMENT DATE" );
        } );

        avc.addTextFieldWithConfigurationHandler( this, function(this:InstallmentCell, textField:MUITextField){
            textField.placeholderText = MIOLocalizeString( "COMMENTS", "COMMENTS" );
        } );    

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString( "OK", "OK" ), MUIAlertActionStyle.Default, this, function(this:InstallmentCell){
            this.checkPaymentAmountString( status, avc.textFields[0].text.trim(), avc.textFields[1].text.trim());
        } ));

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        AppHelper.sharedInstance().presentViewController( avc, true );   
    }

    private voidInvoice() {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        if ( this.installment instanceof LegalEntityPaymentInstallment ) {

            ad.webService.nullifyInvoice( this.installment.invoice, (data:any, error:string) => {
                if (error == null) {
                    AppHelper.showInfoMessage( null, MIOLocalizeString("INFO", "INFO"), MIOLocalizeString( "VOID INVOICE CREATED SUCCESFULLY", "VOID INVOICE CREATED SUCCESFULLY") );
                    this.installment.status = PaymentInstallmentStatus.void;
                }
                else {
                    AppHelper.showErrorMessage( null, MIOLocalizeString("ERROR", "ERROR"), MIOLocalizeString( "VOID INVOICE COULDN'T BE CREATED", "VOID INVOICE COULDN'T BE CREATED") );
                }
            });
        }    
    }   

    private showReclaimInvoiceAlert() {
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString( "INFO", "INFO" ), MIOLocalizeString( "RECLAIM AMOUNT", "RECLAIM AMOUNT" ), MUIAlertViewStyle.Default );

        avc.addTextFieldWithConfigurationHandler( this, function(this:InstallmentCell, textField:MUITextField){
            textField.placeholderText = MIOLocalizeString( "NEW INVOICE AMOUNT", "NEW INVOICE AMOUNT" );
        } );

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString( "OK", "OK" ), MUIAlertActionStyle.Default, this, function(this:InstallmentCell){
            this.reclaimInvoice( avc.textFields[0].text.trim() );
        } ));

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString( "CANCEL", "CANCEL" ), MUIAlertActionStyle.Cancel, null, null));

        AppHelper.sharedInstance().presentViewController( avc, true );   
    }

    private reclaimInvoice(amount:string) {

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let total = ad.currencyFormatter.numberFromString( amount );        

        if ( this.installment instanceof LegalEntityPaymentInstallment ) {
            ad.webService.createReclaimInvoice( this.installment.invoice, total, null);
        }
    } 

    private checkPaymentAmountString( status:PaymentInstallmentStatus, date:string, comments:string ) {

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;        
        let d = ad.dateFormatter.dateFromString( date );

        this.installment.status = status;
        this.installment.paymentDate = d;
        this.installment.comments = comments ?? "";
        
        DBHelper.saveMainContext();
    }

    private showReclaimInstallmentAlert() {

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString( "INFO", "INFO" ), MIOLocalizeString( "RECLAIM OPTIONS", "RECLAIM OPTIONS" ), MUIAlertViewStyle.Default );

        avc.addTextFieldWithConfigurationHandler( this, function(this:InstallmentCell, textField:MUITextField){
            textField.placeholderText = MIOLocalizeString( "NEW DATE", "NEW DATE" );
        } );

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString( "OK", "OK" ), MUIAlertActionStyle.Default, this, function(this:InstallmentCell){
            this.reclaimInstallment( avc.textFields[0].text.trim() );
        } ));

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString( "CANCEL", "CANCEL" ), MUIAlertActionStyle.Cancel, null, null) );

        AppHelper.sharedInstance().presentViewController( avc, true );  
    } 


    private reclaimInstallment(dateString:string) {

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let date = ad.dateTimeFormatter.dateFromString( dateString.substring(0, 10) + " 08:00:00" );        
        ad.webService.duplicateInstallment( this.installment, date, null );        
    } 

    private showSEPAOptionsAlert() {

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString( "INFO", "INFO" ), MIOLocalizeString( "SEPA OPTIONS", "SEPA OPTIONS" ), MUIAlertViewStyle.Default );

        avc.addComboBoxWithConfigurationHandler( this, function(this:InstallmentCell, comboBox:MUIComboBox){            
            comboBox.addItem( "SEPA CORE", 0 );
            comboBox.addItem( "SEPA B2B", 1 );
            comboBox.selectItem(0);        
        } );

        avc.addComboBoxWithConfigurationHandler(this, function (this:InstallmentCell, comboBox: MUIComboBox) {        
            DBHelper.sharedInstance().addObserverForEntity(avc, "BankAccount", null, [MIOSortDescriptor.sortDescriptorWithKey( "info.title", true)], ["info"], function (objects:BankAccount[]) {
                comboBox.removeAllItems();
                for (let i = 0; i < objects.length; i++)
                    comboBox.addItem(objects[i].info.title, i);
            });
        });

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString( "OK", "OK" ), MUIAlertActionStyle.Default, this, function(this:InstallmentCell){
            let type = avc.comboBoxes[0].getSelectedItem();
            let account_index = avc.comboBoxes[1].getSelectedItem();
            
            let account = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, account_index, 'BankAccount') as BankAccount;
            
            this.generateSEPAFromInstallment( parseInt(type), account );
        } ));

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString( "CANCEL", "CANCEL" ), MUIAlertActionStyle.Cancel, null, null) );

        avc.addCompletionHandler(this, function (this:InstallmentCell) {
            // Release the observers
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc.comboBoxes[0], 'StockCustomConcept');
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc.comboBoxes[1], 'Warehouse');            
        });

        AppHelper.sharedInstance().presentViewController( avc, true );  
    }

    private generateSEPAFromInstallment( type:number, account:BankAccount ) {
    
        let sepa_type = "19.14";
        switch(type) {
            case 0: sepa_type = "19.14"; break; // SEPA CORE
            case 1: sepa_type = "19.44"; break; // SEPA B2B
        }

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;                
        let date_str = ad.serverDateFormatter.stringFromDate( this.installment.dueDate );

        let filename = "SEPA_" + account.info.title + "_" + date_str + ".txt";
        
        ad.webService.generateSEPAFileFromInstallments( [this.installment], sepa_type, account, date_str, filename, null );
    }
}