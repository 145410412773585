
class InvoiceDetailHeaderView extends MUIView
{ 
    protected documentNumberLabel:MUILabel = null;
    protected referenceLabel:MUILabel = null;
    protected referenceTextField:MUITextField = null;
    protected dateButton:MUIButton = null;
    protected dateTextField:MUITextField = null;
    protected dateLabel:MUILabel = null;
    protected maturityDropdown:MUIButton = null;
    protected legalAccountNumberLabel:MUILabel = null;
    protected legalEntityLabel:MUILabel = null;
    protected nextDocumentIDLabel:MUILabel = null;

    protected processButton:MUIButton = null;

    awakeFromHTML(){     
        this.documentNumberLabel = MUIOutlet(this, "document-number-lbl", "MUILabel");
        
        this.referenceLabel = MUIOutlet(this, "reference-lbl", "MUILabel");
        this.referenceTextField = MUIOutlet(this, "reference-tf", "MUITextField");
        this.setupReferenceTextField(this.referenceTextField);
        
        this.dateButton = MUIOutlet(this, "date-btn", "MUIButton");
        this.setupCalendarButton(this.dateButton);

        this.dateTextField = MUIOutlet(this, "date-tf", "MUITextField");
        this.setupDocumentDateTextField(this.dateTextField);
        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel");

        this.legalAccountNumberLabel = MUIOutlet(this, "le-account-number-lbl", "MUILabel");
        this.legalEntityLabel = MUIOutlet(this, "legal-entity-lbl", "MUILabel");
        this.nextDocumentIDLabel = MUIOutlet(this, "next-document-id-lbl", "MUILabel");

        this.maturityDropdown = MUIOutlet(this, "maturity-dd", "MUIButton");        
        if (this.maturityDropdown) this.maturityDropdown.setAction(this, function(){
            AppHelper.sharedInstance().showSelectMaturityPaymentViewControllerFromView(this.maturityDropdown, null, this, this.updateMaturity);
        });
        
        this.processButton = MUIOutlet(this, "process-btn", "MUIButton");
        if (this.processButton != null) this.processButton.setAction(this, this.showProcessAlert);
    }

    private _invoice:Invoice = null;
    set invoice(item:Invoice) {
        this._invoice = item;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        
        if (this.documentNumberLabel) this.documentNumberLabel.text = item.documentNumber;        
        if (item instanceof PurchaseInvoice) {
            if (this.referenceLabel) this.referenceLabel.text = item.externalReference;
            if (this.referenceTextField) this.referenceTextField.text = item.externalReference;
        }

        let date_str = ad.dateTimeFormatter.stringFromDate( item.date );
        if (this.dateLabel) this.dateLabel.text = date_str;
        if (this.dateTextField) this.dateTextField.text = date_str;        

        this.legalAccountNumberLabel.text = item.legalEntity.accountNumber;
        this.legalEntityLabel.text = item.legalEntity.name;

        if (this.maturityDropdown) this.maturityDropdown.title = item.paymentInstallmentTemplateName;
    }

    protected setupReferenceTextField( textField:MUITextField ){
        if (textField == null) return;

        textField.setOnChangeText(this, function(this:InvoiceDetailHeaderView, control:MUITextField, value:string){            
            (this._invoice as PurchaseInvoice).externalReference = value;
        });
    }


    protected setupDocumentDateTextField(textField:MUITextField){
        if (textField == null) return;

        textField.setOnChangeText(this, function(this:InvoiceDetailHeaderView, control:MUITextField, value:string){
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            this._invoice.date = ad.dateTimeFormatter.dateFromString(value);    
        });
    }

    protected setupCalendarButton(button:MUIButton){
        if (button == null) return;

        button.setAction(this, function(){

            let dp = new MUIDatePickerController();
            dp.init();        
            dp.delegate = this;        
            dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;
            // dp.tag = type;

            let pc = dp.popoverPresentationController;
            pc.sourceRect = button.frame;
            pc.sourceView = button;

            AppHelper.sharedInstance().presentViewController(dp, true);
        });
    }

    didSelectDate(datePickerController:MUIDatePickerController, date:Date) {
        // let source = datePickerController.popoverPresentationController.sourceView;
        let newDate = this.newDateFromValue(this._invoice.date, date);
        this._invoice.date = newDate;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        this.dateTextField.text = ad.dateTimeFormatter.stringFromDate( newDate );
        // this.dateDidChange(datePickerController.tag, newDate);
    }

    private newDateFromValue(oldDate:Date, newDate:Date):Date {
        if (oldDate == null) {
            let now = MIODateNow();            
            newDate.setHours(now.getHours());
            newDate.setMinutes(now.getMinutes());
            newDate.setSeconds(now.getSeconds());
            return newDate;
        }
        else {
            newDate.setHours(oldDate.getHours());
            newDate.setMinutes(oldDate.getMinutes());
            newDate.setSeconds(oldDate.getSeconds());
            return newDate;
        }
    }

    protected dateDidChange(type:NoteDetailHeaderViewDateType, date:Date){

    }

    private updateMaturity(controller:SelectViewController, maturity:MaturityPayment) {
        this.maturityDropdown.title = maturity?.name;
        this._invoice.paymentInstallmentTemplateName = maturity?.name;
        this._invoice.paymentInstallmentTemplate = maturity;        
    }

    protected showProcessAlert(){
        if (this._invoice.status != DBDocumentStatus.processed) {
            this.showProcessOptions();
        } 
        else 
        {
            let avc = new MUIAlertViewController();
            avc.initWithTitle(MIOLocalizeString("WARNING", "WARNING"), MIOLocalizeString("ARE YOU SURE YOU WANT TO UNPROCESS THIS DOCUMENT?", "ARE YOU SURE YOU WANT TO UNPROCESS THIS DOCUMENT?"), MUIAlertViewStyle.Default);
            avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("UNPROCESS", "UNPROCESS"), MUIAlertActionStyle.Destructive, this, function(){
                this._invoice.status = DBDocumentStatus.unprocessed;
                DBHelper.saveMainContext();
            }));
            avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));
            AppHelper.sharedInstance().presentViewController(avc, true);
        }
    }

    protected showProcessOptions(){

        let avc = new MUIAlertViewController();
        avc.initWithTitle( MIOLocalizeString("OPTIONS", "OPTIONS"), MIOLocalizeString( "SELECT OPTIONS", "SELECT OPTIONS"), MUIAlertViewStyle.Default );

        avc.addComboBoxWithConfigurationHandler( this, function(this:InvoiceDetailHeaderView, control:MUIComboBox) {
            DBHelper.sharedInstance().addObserverForEntity(avc, "PayMethod", null, null, null, function (objects) {
                control.removeAllItems();
                for (let i = 0; i < objects.length; i++)
                    control.addItem(objects[i].name, i);
            });
        });

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString("OK", "OK"), MUIAlertActionStyle.Default, this, function(this:InvoiceDetailHeaderView) {
            let index = avc.comboBoxes[0].getSelectedItem();
            let pm = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, index, 'PayMethod') as PayMethod;

            this.processDocument( pm );
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        avc.addCompletionHandler(this, function () {
            // Release the observers
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc, 'PayMethod');
        });


        AppHelper.sharedInstance().presentViewController( avc, true );
    }

    private processDocument(paymethod:PayMethod) {        
        this._invoice.payMethod = paymethod;
        this._invoice.status = DBDocumentStatus.processed;
        DBHelper.saveMainContext();
    }

    // protected processStockNote(){
    //     //check that stock note exists
    //     if (this.stockNote == null) {
    //         return;
    //     }

    //     //check that the stock note has lines ("El Documento esta Vacío, Añade una Línea")
    //     if (this.stockNote.lines.length <= 0) {
    //         AppHelper.showErrorMessage(null, "INFO", "CAN PROCESS AN EMPTY DOCUMENT");
    //         return;
    //     }

    //     let sectionID = StockNote.sectionIDFromStockNote( this.stockNote );
    //     if (sectionID == null) return;

    //     let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
    //     let section = DBHelper.queryObject(ad.managedObjectContext, "ManagerSection", MIOPredicate.predicateWithFormat("identifier = " + sectionID)) as ManagerSection;

    //     if (this.stockNote.status != StockNoteStatus.Processed) {
    //         let nextStatus = ad.selectedUser.checkAccessControlFlag(section.bitPosition, UserAccessControlBit.process) == false ? StockNoteStatus.Pending : StockNoteStatus.Processed;
    //         if (this.customStockNoteBeforeChangeStatus(nextStatus) == false) return;

    //         //set date if not found
    //         let now = MIODateNow();

    //         //SHOULD THESE TWO BE SWITCHED???
    //         if (this.stockNote.stockDate == null) this.stockNote.stockDate = now;
    //         this.stockNote.validationDate = now;

    //         if (this.stockNote instanceof InventoryNote) {
    //             this.checkIfCanProcessInventoryNote();
    //         }
    //         else {            
    //             this.changeProcessNoteStatus(nextStatus);
    //         }
    //     } 
    //     else {
    //         if (ad.selectedUser.checkAccessControlFlag(section.bitPosition, UserAccessControlBit.unprocess) == false) {
    //             AppHelper.showErrorMessage(null, "Info", "You don't have permission to unprocess this note");
    //             return;
    //         }

    //         this.showUnporcessAlert();
    //     }        
    // }

    // protected customStockNoteBeforeChangeStatus(status:number) : boolean {
    //     return true;
    // }

    // private updateUI(){
    //     MIONotificationCenter.defaultCenter().postNotification("StockNoteDidProcess", this.stockNote);
    // }

    // private showUnporcessAlert(){
    //     let avc = new MUIAlertViewController();
    //     avc.initWithTitle("Warning", "Are you sure you want to unprocess this document?", MUIAlertViewStyle.Default);

    //     avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("UNPROCESS", "UNPROCESS"), MUIAlertActionStyle.Destructive, this, function(){
    //         this.changeProcessNoteStatus(StockNoteStatus.Unprocessed);
    //     }));

    //     avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

    //     AppHelper.sharedInstance().presentViewController(avc, true);
    // }

    // private changeProcessNoteStatus(status:StockNoteStatus){
    //     this.stockNote.status = status;
    //     DBHelper.saveMainContextWithCompletion(this, function(this:NoteDetailHeaderView) {
    //         AppHelper.showInfoMessage(null, "Info", "The operation was sucessfull");
    //         this.updateUI();    
    //     });
    // }

    // private checkIfCanProcessInventoryNote(){

    //     let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
    //     ad.webService.canProcessStockNote(this.stockNote, this, function(this:NoteDetailHeaderView, data:any, error:string){
    //         if ( error == null || (error != null && error.length == 0) ) {
    //             this.changeProcessNoteStatus(StockNoteStatus.Processed);
    //         }
    //         else if (data != null && data.lenght > 0) {
    //             AppHelper.showErrorMessage(null, "Error", "Couldn't unprocess. The inventory (" + data[0]["sourceDocumentReference"] + ") must be unprocess first!");                
    //         }
    //         else {
    //             AppHelper.showErrorMessage(null, "Error", "Couldn't unprocess. " + error);                
    //         }
    //     });   

    // }
    
}