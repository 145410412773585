 /// <reference path="ReportFilterView.ts" />


class ReportViewController extends MUIViewController implements ReportFilterViewControllerDelegate
{
    static newInstance() : ReportViewController {
        let vc = new ReportViewController("report-detail-view");
        vc.initWithResource("layout/reports/ReportDetailView.html");
        return vc;
    }

    showSectionButton = false;
    showBackButton = false;
    showSplitBackButton = false;
    showNavigationBar = true;
    splitBackButtonTarget = null;
    splitBackButtonCompletion = null;

    private navigationBar:MUIView = null;
    private sectionsButton:MUIButton = null;
    private backButton:MUIButton = null;
    private splitBackButton:MUIButton = null;
    private titleLabel:MUILabel = null;
    private dateLabel:MUILabel = null;

    private refreshButton:MUIButton = null;
    private filterButton:MUIButton = null;
    private visibilityButton:MUIButton = null;
    private exportButton:MUIButton = null;
    
    private filtersView:MUIView = null;
    private resultsView:ReportView = null;

    private loadingView:MUIView = null;

    // private filterViewController:FilterViewController = null;
    
    viewDidLoad(){
        super.viewDidLoad();   

        this.navigationBar = MUIOutlet(this, "nav-bar", "MUIView");

        this.sectionsButton = MUIOutlet(this, "sections-btn", "MUIButton");
        if (this.sectionsButton != null) this.sectionsButton.hidden = true;
        if (this.sectionsButton != null) this.sectionsButton.setAction(this, function(){
            AppHelper.sharedInstance().presentSectionViewController(this);        
        });

        this.backButton = MUIOutlet(this, "back-btn", "MUIButton");
        if (this.backButton != null) {
            this.backButton.hidden = true;
            this.backButton.setAction(this, function(){
                this.navigationController.popViewController(true);
            });
        }
        
        this.splitBackButton = MUIOutlet(this, "sv-back-btn", "MUIButton");
        if (this.splitBackButton != null) {
            this.splitBackButton.hidden = true;
            this.splitBackButton.setAction(this, function(){
                if (this.splitBackButtonTarget != null && this.splitBackButtonCompletion != null) {
                    this.splitBackButtonCompletion.call(this.splitBackButtonTarget);
                }
            });
        }
        
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel");

        this.refreshButton = MUIOutlet(this, "refresh-btn", "MUIButton");
        this.refreshButton.setAction(this, this.refresh);

        this.filterButton = MUIOutlet(this, "filter-btn", "MUIButton");
        this.filterButton.setAction(this, function(this:ReportViewController){
            this.toggleFilterViewController();                
        });
        
        this.visibilityButton = MUIOutlet(this, "visibility-btn", "MUIButton");
        this.visibilityButton.setAction(this, this.visibilityOptionsAction);

        this.exportButton = MUIOutlet(this, "export-btn", "MUIButton");
        if (MIOCoreIsMobile() == true) this.exportButton.hidden = true;
        this.exportButton.setAction(this, function(){
            this.exportReport();
        });
        
        this.resultsView = MUIOutlet(this, "results-view", "ReportView");   
        this.resultsView.delegate = this;     

        this.loadingView = MUIOutlet(this, "loading-view", "MUIView");
        this.loadingView.hidden = true;

        this.filtersView = MUIOutlet(this, "right-view", "MUIView");
        if (this.filtersView == null) return;
        this.filtersView.hidden = true;        
        // this.filterViewController = new FilterViewController("filter-view");
        // this.filterViewController.initWithResource("layout/filter/FilterView.html");
        // this.filterViewController.embeddedController = true;
        // this.filtersView.addSubview(this.filterViewController.view);                
        // this.addChildViewController(this.filterViewController);
        // this.filterViewController.setCompletionBlock(this, function(){
        //     if (this.isDownloading == false && this.filterViewController != null) {
        //         this.isDownloaded = false;
        //         this.filterViewController.setApplyButtonEnable(false);
        //         this.updateUI();
        //     }                        
        // });
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        //this.filtersView.setWidth(0);

        // if (this._report != null) ReportHelper.sharedInstance().filtersFromReport(this._report, this, function(filters){
        //     if (this.filterViewController != null) this.filterViewController.filters = filters;
        // });

        this.updateUI();
    }

    private _report:Report = null;
    set report(item:Report){
        this._report = item;
        this._title = item.name;        
        this._reportID = item.identifier;
        this.reportScriptPath = item.path;        
        this.updateUI();
    }

    private _reportID:string = null;
    set reportID(reportID:string){        
        DBHelper.queryObjectWithCompletion("Report", null, MIOPredicate.predicateWithFormat("identifier == " + reportID), [], this, function(report:Report){
            this.report = report; 
        });                                     
    }

    private _reportPath:string = null;
    set reportScriptPath(path:string){
        this._reportPath = path;
        this.updateUI();
    }

    private _title:string = null;
    set title(title:string){
        this._title = title;
    }

    private _filterValues = null;
    set filterValues(values:any){
        this._filterValues = values;
    }

    private dateIntervalString:string = null;
    private serverDateIntervalString:string = null;
    private isDownloaded = false;
    private isDownloading = false;
    private updateUI(){
        if (this.viewIsLoaded == false) return;
        if (this._report == null && this._reportPath == null) return;
        if (this.isDownloaded == true) return;
        if (this.isDownloading == true) return;
        if (this.showSectionButton == true && this.sectionsButton != null) this.sectionsButton.hidden = false;
        if (this.showBackButton == true && this.backButton != null) this.backButton.hidden = false;
        if (this.showSplitBackButton == true && this.splitBackButton != null) this.splitBackButton.hidden = false;
        this.navigationBar.hidden = !this.showNavigationBar;
        this.titleLabel.text = MIOLocalizeString(this._title?.toUpperCase(), this._title);
        
        this.updateDateTimeRange();
        this.downloadReport();
    }

    private updateDateTimeRange() {        
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let rh = ReportHelper.sharedInstance();
        let from = ad.dateFormatter.stringFromDate(rh.selectedDateTimeFrom);
        let to = ad.dateFormatter.stringFromDate(rh.selectedDateTimeTo);
        if (from == to) {
            this.dateIntervalString = from;
            this.serverDateIntervalString = ad.serverDateFormatter.stringFromDate(rh.selectedDateTimeFrom);
        }
        else {
            this.dateIntervalString = from + " - " + to;
            let serverFrom = ad.serverDateFormatter.stringFromDate(rh.selectedDateTimeFrom);
            let serverTo = ad.serverDateFormatter.stringFromDate(rh.selectedDateTimeTo);
            this.serverDateIntervalString = serverFrom + "_" + serverTo;
        }
        
        this.dateLabel.text = this.dateIntervalString;
    }

    reloadData(){
        this.downloadReport();
    }

    private filterOptions = [];
    private toggleFilterViewController(){   

        if (this.resultsView.filters.length > 0) {
            let vc = ReportFilterViewController.newInstance();
            vc.delegate = this;
            vc.reportFilters = this.filterOptions;
            this.presentViewController(vc, true);
        }
        else {
            AppHelper.sharedInstance().presentDateSelectionViewController(this);
        }        
    }

    applyFilters(filters: any[]): void {
        if (filters["DATE"] != null) {
            const f = filters["DATE"];
            this.dateLabel.text = f.valueString;
        }
        
        this.downloadReport();
    }
    
    private isDisplayFilterView = false;
    private showFilterView(){
        if (this.isDisplayFilterView == true) return;
        
        MUIView.animateWithDuration(0.15, this, function(){
            this.isDisplayLogView = true;
            this.filtersView.setWidth(320);
        }, function(){
            this.filtersView.setNeedsDisplay();
        });
    }

    private hideFilterView(){
        if (this.isDisplayFilterView == false) return;

        MUIView.animateWithDuration(0.15, this, function(){
            this.isDisplayLogView = false;
            this.filtersView.setWidth(0);
        }, function(){
            this.filtersView.setNeedsDisplay();
        });
    }


    datesDidSelect(dateTimeFrom, dateTimeTo) {
        let rh = ReportHelper.sharedInstance();
        rh.selectedDateTimeFrom = dateTimeFrom;
        rh.selectedDateTimeTo = dateTimeTo;   
        this.isDownloaded = false;
        this.updateUI();
    }
    
    private reportData = null;
    private downloadReport(){
        this.resultsView.clear();
        this.downloadReportResults();        
    }


    private downloadReportResults(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let ws = ad.webService as WebService;                

        let filters = {"__report_output_type__": 1}; // Force JSON output render        

        let places = DBHelper.queryObjectsFromMainContext( "Place", null, MIOPredicate.predicateWithFormat( "deletedAt = null" ), [] );
        if ( places.length > 0 ) filters["level"] = "company";

        // Add filter values from another report
        if (this._filterValues != null){
            for (let key in this._filterValues){
                let value = this._filterValues[key];
                filters[key] = value != null ? this._filterValues[key] : "NULL";
            }
        }

        // Add the filter options
        for (let f of this.filterOptions) {
            if (f["Value"] == null) continue;
            filters[f["Key"]] = f["Value"];
        }

        filters["session_date"] = ReportHelper.sharedInstance().selectedServerDateTimeFromString + "," + ReportHelper.sharedInstance().selectedServerDateTimeToString;

        // let filterOptions = this.filterViewController.filters != null ? this.filterViewController.filters : [];
        // for (let index = 0; index < filterOptions.length; index++){
        //     let fo = filterOptions[index] as FilterOption;

        //     if (fo.value != null) {
        //         if (fo.type == FilterControllerValueType.Number) filters[fo.key] = fo.value;
        //         else {
        //             //filters[fo.key] = fo.value.replace(/"([^"]*)"/, "");
        //             let value = fo.value;
        //             if (value.hasPreffix("'") && value.hasSuffix("'")) value = fo.value.substring(1, fo.value.length - 1);
        //             if (value.hasPreffix("\"") && value.hasSuffix("\"")) value = fo.value.substring(1, fo.value.length - 1);
        //             filters[fo.key] = value;
        //         }                
        //     }
        // }

        this.loadingView.hidden = false;
        ws.executeReportScriptAtPath(this._reportPath, filters, this, function(this:ReportViewController, code:number, data:any){
            this.loadingView.hidden = true;
            this.isDownloaded = true;
            this.isDownloading =  false;
            // this.filterViewController.setApplyButtonEnable(true);
            if (code != 200) {
                this.resultsView.logText("Error executing script (" + code + ")");
                return;
            }        
            this.resultsView.reportID = this._reportID;
            this.resultsView.clear();
            this.resultsView.parseResponse(data);
            this.reportData = data;
            
            let filterKeys = this.filterOptions.map( function( f ) { return f["Key"]; });

            for (let f of this.resultsView.filters) {
                let key = f["Key"];
                if (filterKeys.containsObject(key)) continue;
                this.filterOptions.addObject(f);
            }
        });
    }

    performSegueWithIdentfier(segueIdentifier, values){                        
        
        if (MIOCoreIsPhone() == true && segueIdentifier != "ShowReportViewController") return;

        let vc = null;
        switch(segueIdentifier){            
            case "ShowReportViewController":
                vc = this.reportViewController(values);
                break;

            case "ShowTicketShapshotViewController":
                vc = new TicketSnapShotViewController("printer-snapshot-view");
                vc.initWithResource("layout/printersnapshot/PrinterSnapshotView.html");
                vc.filterValues = values;
                break;

            case "ShowTicketDetailViewController":
                vc = ArchivedDocumentDetailViewController.newInstance();
                vc.filterValues = values;
                vc.showBackButton = true;
                break;

            case "ShowWorkerSessionViewController":
                vc = WorkerSessionViewController.newInstance();                
                vc.filterValues = values;
                vc.showFromReport = true;
                vc.setOnSaveBlock(this, function(){
                    this.downloadReport();
                });
                break;
			
			case "ShowInventoryNoteDetailViewController":
                vc = new InventoryNoteDetailViewController('inventory-note-detail-view');
                vc.initWithResource('layout/inventorynote/InventoryNoteDetailView.html');   
                vc.filterValues = values;
                vc.showBackButton = true;
                break;

            case "ShowBookingDetailViewController":
                vc = new BookingDetailViewController("booking-detail-view");
                vc.initWithResource("layout/bookings/bookings/BookingsDetailView.html");
                vc.filterValues = values;                
                break;
            }    

        if (vc == null || this.navigationController == null) return;
        this.navigationController.pushViewController(vc, true);
    }

    private reportViewController(values){
        let vc = new ReportViewController("report-detail-view");
        vc.initWithResource("layout/reports/ReportDetailView.html");            
        vc.showSectionButton = false;
        vc.showBackButton = true;            

        let rid = values["ReportID"];
        let rp = values["ReportPath"];
        let filters = values["filters"];

        vc.filterValues = filters;
        if (rp == null) vc.reportID = rid;
        else vc.reportScriptPath = rp;        
        
        return vc;
    }

    exportReport(){
        let filename = this.serverDateIntervalString + (this._title ? "_" + this._title : "");        
        ReportHelper.sharedInstance().showExportActions(filename, this._title, this.dateIntervalString, this.reportData, this._report?.identifier, this.resultsView.reportOptions);
    }
    
    visibilityOptionsAction(button:MUIButton){
        let vc = ReportColumnVisibilityViewController.newInstance();
        vc.delegate = this;
        vc.setColumnOptions(this._reportID, this.resultsView.reportOptions);
        AppHelper.sharedInstance().presentPopOverViewController(vc, button, true);
    }

    applyVisibilityOptions(options:any) {
        this.resultsView.clear();
        // this.resultsView.reportOptions = options;
        this.resultsView.parseResponse(this.reportData);
    }

    private refresh() {
        this.downloadReport();
    }
}