class  FormatAdminCell extends UITableViewCell
{
    private venueNameLabel:MUILabel = null;    
    private productNameLabel:MUILabel = null;    
    private categoryNameLabel:MUILabel = null;    
    private enabledSalesSwitch:MUISwitchButton = null;

    private formatTextField:MUITextField = null;
    private formatTextfieldByID = {};

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;

    awakeFromHTML(){
        this.venueNameLabel = MUIOutlet(this, "venue-lbl", "MUILabel");        
        this.categoryNameLabel = MUIOutlet(this, "category-lbl", "MUILabel");
        this.productNameLabel = MUIOutlet(this, "name-lbl", "MUILabel");        

        // this.enabledSalesSwitch = MUIOutlet(this, "enabled-sales-sw", "MUISwitchButton");
        // this.enabledSalesSwitch.setOnChangeValue(this, function(this:ProductAdminCell, control:MUISwitchButton, value:boolean) {             
        // });

        this.formatTextField = MUIOutlet(this, "text-tf", "MUITextField");
        if(this.formatTextField) this.formatTextField.hidden = true;

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private _item:ProductMap = null;
    setItem (item:ProductMap, formats:Format[], isDetailFormat:boolean){
        this._item = item;

        if (!isDetailFormat) {
            this.venueNameLabel.text = item.venueName;
            this.categoryNameLabel.text = item.categoryName;
            this.productNameLabel.text = item.productName;            
            // this.enabledSalesSwitch.on = item.isDefaultFormat;
        } 
        else {

            if (Object.keys(this.formatTextfieldByID).length == 0){
                for (let f of formats){
                    let tf = MIOClassFromString("MUITextField");
                    let newLayer = this.formatTextField.layer.cloneNode(true);
                    newLayer.style.display = "";
                    tf.initWithLayer(newLayer, this);
                    tf.awakeFromHTML();            
                    tf.placeholderText = "";
    
                    this.addSubview(tf);
                    this.formatTextfieldByID[f.identifier] = tf;

                    tf.enabled = false;
                }
            }
    
            for ( let pf of item.formats ){
                let tf = this.formatTextfieldByID[pf.formatID];
                tf.text = this.cf.stringFromNumber( pf.amount );
                tf.enabled = true;
                tf.setOnChangeText(this, function(this:FormatAdminCell, control:MUITextField, value:string){
                    let price = this.cf.numberFromString(value);
                    pf.amount = price == null ? 0 : price;
                    pf.productFormatPlace.price = price == null ? 0 : price;
                });
            }
        }    
    }
}