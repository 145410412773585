class ProductAdminDataSource extends MIOObject
{
    private filterHeader:MUIView = null;
    private tableView:UITableView = null;

    private filterController:ColumnFilterController = null;

    private venueTextField:ColumnFilterTextField = null;
    private categoryTextField:ColumnFilterTextField = null;
    private productTextField:ColumnFilterTextField = null;

    private filterController:ColumnFilterController = null;
    
    placesByID = {};

    initWithTableView(tableView:UITableView, filterHeader:MUIView, owner:MUIViewController){
        this.tableView = tableView;
        this.filterHeader = filterHeader;
        
        this.filterController = new ColumnFilterController();
        this.filterController.initWithDelegate(this);
        this.filterController.customKeyPredicateFunction["venue-name"] = (value:any) : string|null => {
            let array = value.toLowerCase()
                             .replaceAll("venue-name contains ", "")
                             .replaceAll("'", "")
                             .split("or")
                             .map((item:string) => { return item.trim() } );
            
            let places = [];

            for (let id in this.placesByID) {
                let name = ( this.placesByID[ id ] ).toLowerCase();
                for (let v of array) {
                    if ( name.indexOf(v) >= 0 ) {
                        places.push(id);
                        break;
                    }
                }                
            }

            if (places.length == 0) return null;
            
            return "placeID in ['" + places.join("','") + "']";
        }

        this.venueTextField = MUIOutlet(owner, "venue-filter-tf", "ColumnFilterTextField");
        this.venueTextField.filterController = this.filterController;
		this.venueTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "venue-name", null, null);

        this.categoryTextField = MUIOutlet(owner, "category-filter-tf", "ColumnFilterTextField");
        this.categoryTextField.filterController = this.filterController;
		this.categoryTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "product.category.name", null, null);

        this.productTextField = MUIOutlet(owner, "product-filter-tf", "ColumnFilterTextField");
        this.productTextField.filterController = this.filterController;
		this.productTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "product.name", null, null);
    }    

    reloadData(){
        this._product_count = -1;
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){        
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    titleForHeaderInSection(tableView:UITableView, section:number) {
        let ip = MIOIndexPath.indexForRowInSection(0, section);
        let item = this.fetchedResultsController.objectAtIndexPath(ip) as ProductComponentItem;
        return item.categoryName;
    }

    private _last_product_id = null
    private _product_count = -1;
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let item = this.fetchedResultsController.objectAtIndexPath( indexPath ) as ProductPlace;

        let cell:ProductAdminCell = null;
        cell = tableview.dequeueReusableCellWithIdentifier("ProductAdminCell") as ProductAdminCell;
                
        cell.setItem( item, this.placesByID[item.placeID] );

        if (this._last_product_id != item.product.identifier) {
            this._last_product_id = item.product.identifier;
            this._product_count++;
        }

        if ( this._product_count % 2 != 0 ) {
            MUICoreLayerAddStyle(cell.layer, "bg-grey");
        }
        
        return cell;
    }


    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;
                
        let sds = [ MIOSortDescriptor.sortDescriptorWithKey("product.category.name", true), 
                    MIOSortDescriptor.sortDescriptorWithKey("product.name", true)];

        let predicateFormat = "placeID != '" + ad.selectedIdentifier + "' && deletedAt = null && product.category != null"
        let filterPredicateFormat = this.filterController.filterPredicateFormat();
        if (filterPredicateFormat != null){
            predicateFormat += " AND (" + filterPredicateFormat + ")";
        }
            

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("ProductPlace", sds, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = [ "product", "product.category" ];
        //fetchRequest.fetchLimit = 500;

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    filterPredicateDidChange(controller:ColumnFilterController){
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

}