
class WorkerSessionViewController extends MUIViewController
{
    static newInstance() : WorkerSessionViewController {
        let vc = new WorkerSessionViewController("worker-sessions-view");
        vc.initWithResource("layout/workers/WorkerSessionsView.html");
        return vc;
    }

    showFromReport = false;    

    private navBar:MUIView = null;
    private backButton:MUIButton = null;
    private titleLabel:MUILabel = null;
    private saveButton:MUIButton = null;
    
    private totalLabel:MUILabel = null;
    private timeRangeLabel:MUILabel = null;
    private calendarButton:MUIButton = null;
    private tableView:MUITableView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.navBar = MUIOutlet(this, "nav-bar", "MUIView");
        this.backButton = MUIOutlet(this, "back-btn", "MUIButton");
        this.backButton.setAction(this, function(){
            this.navigationController.popViewController(true);
        });

        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");        
        this.titleLabel.text = MIOLocalizeString("DATE RANGE:", "Date range:") + " " + MIOLocalizeString("TODAY", "Today");

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContextWithCompletion(this.target, this.completion);
        });

        this.totalLabel = MUIOutlet(this, "total-lbl", "MUILabel");        

        this.timeRangeLabel = MUIOutlet(this, "time-range-lbl", "MUILabel");
        this.timeRangeLabel.text = MIOLocalizeString("DATE RANGE:", "Date range:") + " " + MIOLocalizeString("TODAY", "Today");        

        this.calendarButton = MUIOutlet(this, "calendar-btn", "MUIButton");
        this.calendarButton.setAction(this, function(){
            AppHelper.sharedInstance().presentDateSelectionViewController(this);
        });

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private target = null;
    private completion = null;
    setOnSaveBlock(target, completion){
        this.target = target;
        this.completion = completion;
    }

    private _worker:Employee = null;
    set worker(worker:Employee){
        this._worker = worker;
        this.updateUI();
    }

    set filterValues(values:any){
        let identifier = values["id"];
        let beginDate = values["begin_date"];
        let endDate = values["end_date"];
        if (identifier == null) return;
        
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        this.dateTimeFrom = ad.serverDateTimeFormatter.dateFromString(beginDate);
        this.dateTimeTo = ad.serverDateFormatter.dateFromString(endDate);

        DBHelper.queryObjectsWithCompletion("Employee", null, MIOPredicate.predicateWithFormat("identifier == '" + identifier + "'"), [], this, function(objects){
            if (objects.length > 0) {
                let worker = objects[0] as Employee;
                this.worker = worker;
            }
        });
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._worker == null) return;        

        if (this.showFromReport) {
            this.navBar.hidden = false;
            this.timeRangeLabel.hidden = true;
            this.calendarButton.hidden = true;
        }
        else {
            this.navBar.hidden = true;
            this.timeRangeLabel.hidden = false;
            this.calendarButton.hidden = false;
        }        

        this.totalLabel.text = MIOLocalizeString("TOTAL SESSIONS HOURS:", "Total sessions hours:") + " 0";                

        this.fetchedResultsController = null;
        this.tableView.reloadData();                

        this.updateDateRange();
        this.updateTotalHours();
    }

    private updateDateRange(){
        if (this.dateTimeFrom == null || this.dateTimeTo == null) return;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;    
        
        let dayFrom = MIODateGetDateString(this.dateTimeFrom);
        let dayTo = MIODateGetDateString(this.dateTimeTo);

        if (dayFrom == dayTo) {
            this.timeRangeLabel.text = MIOLocalizeString("DATE RANGE:", "Date range:") + " " + ad.longDateFormatter.stringFromDate(this.dateTimeFrom);            
        }
        else {
            this.timeRangeLabel.text = MIOLocalizeString("DATE RANGE:", "Date range:") + " " + ad.dateFormatter.stringFromDate(this.dateTimeFrom) + ' - ' + ad.dateFormatter.stringFromDate(this.dateTimeTo);
        }

        this.titleLabel.text = this.timeRangeLabel.text;
    }

    private updateTotalHours(){
        let total = 0;

        for (let index = 0; index < this.fetchedResultsController.fetchedObjects.length; index++){
            let session = this.fetchedResultsController.fetchedObjects[index] as WorkSession;
            total += session.workedHours;
        }

        let time = total % 1;
        let minutes = Math.floor(time * 60);
        let hours = Math.floor(total);
        // let time = new Date(total);

        // let hrs = time.getTimezoneOffset() / 60;
        // time.setHours(time.getHours() + hrs);

        let text = MIOLocalizeString("TOTAL SESSIONS HOURS:", "Total sessions hours:") + " ";
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate
        //text += ad.timeFormatter.stringFromDate(time);
        if (hours > 0) text += hours + " " + MIOLocalizeString("HOURS", "Hours") + ", ";
        text += minutes + " " + MIOLocalizeString("MINUTES", "Minutes");

        this.totalLabel.text = text;
    }

    numberOfSections(tableView:MUITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableView:MUITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("WorkerSessionCell") as WorkerSessionCell;
        let session = this.fetchedResultsController.objectAtIndexPath(indexPath) as WorkSession;
        
        cell.setItem(session, indexPath.row + 1);        

        return cell;
    }

    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }
    
    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {

        let session = this.fetchedResultsController.objectAtIndexPath(indexPath) as WorkSession;    

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            this.showRemoveWorkSessionAlert(session);
        }
    }

    protected _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value:MIOFetchedResultsController){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController() : MIOFetchedResultsController {
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        
        let sds = [MIOSortDescriptor.sortDescriptorWithKey("beginDate", false)];        
        
        let dateFrom = this.dateTimeFrom;
        if (dateFrom == null) {
            dateFrom = MIODateToday();
            dateFrom.setHours(0);
            dateFrom.setMinutes(0);
            dateFrom.setSeconds(0);
            dateFrom.setMilliseconds(0);
        }

        let dateTo = this.dateTimeTo;
        if (dateTo == null) {
            dateTo = MIODateToday();
            dateTo.setHours(23);
            dateTo.setMinutes(59);
            dateTo.setSeconds(59);
            dateTo.setMilliseconds(0);
        }
        
        let from = ad.serverDateTimeFormatter.stringFromDate(dateFrom);
        let to = ad.serverDateTimeFormatter.stringFromDate(dateTo);

        let predicateFormat = "worker.identifier == " + this._worker.identifier;
        predicateFormat += " AND (beginDate >= '" + from + "' AND beginDate <= '" + to + "')";
        if (ad.selectedIdentifierType == "place") predicateFormat += "placeID == " + ad.selectedIdentifier; 
        
        // if (this.searchString != null)
        //     fetchRequest.predicate = MIOPredicate.predicateWithFormat("name CONTAINS \"" + this.searchString + "\"");

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("WorkSession", sds, predicateFormat);        

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController) {
        this.tableView.reloadData();
        this.updateTotalHours();
    }

    private dateTimeFrom:Date = null;
    private dateTimeTo:Date = null;
    datesDidSelect(dateTimeFrom:Date, dateTimeTo:Date) {        
        this.dateTimeFrom = dateTimeFrom;
        this.dateTimeTo = dateTimeTo;

        this.updateUI();
    }

    private showRemoveWorkSessionAlert(workSession:WorkSession){
        let avc = new MUIAlertViewController();
        avc.initWithTitle("Warning", "Are you sure you want to remove the work session?", MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK", "OK"), MUIAlertActionStyle.Default, this, function(){
            DBHelper.deleteObjectFromMainContext(workSession, true);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "Cancel"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

}