class ProductFormatAdminDataSource extends MIOObject
{
    private filterHeader:MUIView = null;

    private venueTextField:ColumnFilterTextField = null;
    private categoryTextField:ColumnFilterTextField = null;
    private productTextField:ColumnFilterTextField = null;

    private tableView:UITableView = null;
        
    private formatFilterHeader:UIScrollView = null;
    private formatTableView:UITableView = null;    

    private filterController:ColumnFilterController = null;
    
    placesByID = {};
    formats = [];

    initWithTableView(tableView:UITableView, filterHeader:MUIView, formatTableView:UITableView, formatHeader:UIScrollView,  owner:MUIViewController){
        this.tableView = tableView;
        this.filterHeader = filterHeader;
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        
        this.formatTableView = formatTableView;
        this.formatFilterHeader = formatHeader;
        this.formatTableView.dataSource = this;
        this.formatTableView.delegate = this;

        this.filterController = new ColumnFilterController();
        this.filterController.initWithDelegate(this);

        this.venueTextField = MUIOutlet(owner, "format-venue-filter-tf", "ColumnFilterTextField");
        this.venueTextField.filterController = this.filterController;
		this.venueTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "venueName", null, null);

        this.categoryTextField = MUIOutlet(owner, "format-category-filter-tf", "ColumnFilterTextField");
        this.categoryTextField.filterController = this.filterController;
		this.categoryTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "categoryName", null, null);

        this.productTextField = MUIOutlet(owner, "format-product-filter-tf", "ColumnFilterTextField");
        this.productTextField.filterController = this.filterController;
		this.productTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "productName", null, null);

        let tf = MUIOutlet(owner, "titlebar-tf", "MUILabel");
        tf.hidden = true;

        DBHelper.queryObjectsWithCompletion("Format", [MIOSortDescriptor.sortDescriptorWithKey("name", true)], MIOPredicate.predicateWithFormat("deletedAt = null"), [], this, function(this:ProductFormatAdminDataSource, formats:Format[]){
            this.formats = formats;
            for (let f of formats) {
                let view = MIOClassFromString("MUILabel");
                let newLayer = tf.layer.cloneNode(true);
                newLayer.style.display = "";
                view.initWithLayer(newLayer, this);
                view.awakeFromHTML();
                view.text = f.name;
    
                this.formatFilterHeader.addSubview(view);
            }
        });
    }    

    reloadData(){        
        this.fetchedResultsController = null;
        this.productFormatMapping(this.fetchedResultsController.fetchedObjects as ProductFormatPlace[]);
        this._product_count = -1;
        this._last_product_id = null;
        this.tableView.reloadData();
        this._product_count = -1;
        this._last_product_id = null;
        this.formatTableView.reloadData();
    }

    numberOfSections(tableView:UITableView){
        // return this.fetchedResultsController.sections.length;
        return 1;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){        
        // let sec = this.fetchedResultsController.sections[section];
        // return sec.numberOfObjects();
        return this.product_formats.length;
    }

    // titleForHeaderInSection(tableView:UITableView, section:number) {
        // let ip = MIOIndexPath.indexForRowInSection(0, section);
        // let item = this.fetchedResultsController.objectAtIndexPath(ip) as ProductComponentItem;
//        let item = this.product_formats[indexPath.row];
        //return item.categoryName;
    //}

    private _last_product_id = null
    private _product_count = -1;
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        // let item = this.fetchedResultsController.objectAtIndexPath( indexPath ) as ProductFormatPlace;
        let item = this.product_formats[indexPath.row];
        
        let cell = tableview.dequeueReusableCellWithIdentifier("FormatAdminCell") as FormatAdminCell;
                
        cell.setItem( item, this.formats, (this.formatTableView == tableview) );

        if (this._last_product_id != item.productID) {
            this._last_product_id = item.productID;
            this._product_count++;
        }

        if ( this._product_count % 2 != 0 ) {
            MUICoreLayerAddStyle(cell.layer, "bg-grey");
        }
        
        return cell;
    }


    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;
                
        let sds = [ //MIOSortDescriptor.sortDescriptorWithKey("placeID", true),
                    //MIOSortDescriptor.sortDescriptorWithKey("productFormat.product.category.name", true),
                    //MIOSortDescriptor.sortDescriptorWithKey("productFormat.product.name", true)
                    ];
        
        let predicateFormat = "deletedAt = null && placeID != '" + ad.selectedIdentifier + "' && productFormat.product.category != null"
        // let filterPredicateFormat = this.filterController.filterPredicateFormat();
        // if (filterPredicateFormat != null){
        //     predicateFormat += " AND (" + filterPredicateFormat + ")";
        // }            

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("ProductFormatPlace", sds, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = [ "productFormat.product", "productFormat.format", "productFormat.product.category" ];
        //fetchRequest.fetchLimit = 500;

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    private fetched_product_formats:ProductMap[] = [];
    controllerDidChangeContent(controller){
        this.productFormatMapping(this.fetchedResultsController.fetchedObjects as ProductFormatPlace[]);
        this._product_count = -1;
        this._last_product_id = null;
        this.tableView.reloadData();
        this._product_count = -1;
        this._last_product_id = null;
        this.formatTableView.reloadData();
    }
    
    filterPredicateDidChange(controller:ColumnFilterController){
        let pf = this.filterController.filterPredicateFormat();
        this.product_formats = pf == null ? this.fetched_product_formats : _MIOPredicateFilterObjects(this.fetched_product_formats, MIOPredicate.predicateWithFormat( pf ));
        this._product_count = -1;
        this._last_product_id = null;
        this.tableView.reloadData(); 
        this._product_count = -1;
        this._last_product_id = null;       
        this.formatTableView.reloadData();
    }

    private product_formats:ProductMap[] = [];
    private productFormatMapping(objects:ProductFormatPlace[]){        
        this.product_formats = [];
        let product_by_ref = {};
        for ( let obj of objects ){
            let ref = obj.productFormat.product.identifier + "#" + obj.placeID;
            let p = product_by_ref[ ref ];
            if (p == null ){
                p = new ProductMap();                
                p.venueName = this.placesByID[obj.placeID];
                p.productID = obj.productFormat.product.identifier;
                p.productName = obj.productFormat.product.name;
                p.categoryName = obj.productFormat.product.category.name;
                product_by_ref[ ref ] = p;   
                this.product_formats.push(p);
            }

            let f = new ProductFormatMap();
            f.id = obj.identifier;
            f.formatName = obj.productFormat.format.name;
            f.formatID = obj.productFormat.format.identifier;
            f.amount = obj.price;            
            f.productFormatPlace = obj;

            p.formats.push(f);
        }

        this.product_formats = _MIOSortDescriptorSortObjects(this.product_formats, [MIOSortDescriptor.sortDescriptorWithKey("categoryName", true), MIOSortDescriptor.sortDescriptorWithKey("productName", true), MIOSortDescriptor.sortDescriptorWithKey("venueName", true)]);
        this.fetched_product_formats = this.product_formats;
    }

    // scrollViewWillBeginDragging(scrollView:UIScrollView){
    //     if (scrollView == this.tableView) this.formatTableView.contentOffset = new MIOPoint(this.formatTableView.contentOffset.x, scrollView.contentOffset.y);
    //     if (scrollView == this.formatTableView) {
    //         this.tableView.contentOffset = new MIOPoint(this.tableView.contentOffset.x, scrollView.contentOffset.y);
    //         this.formatFilterHeader.contentOffset = new MIOPoint(scrollView.contentOffset.x, this.formatFilterHeader.contentOffset.y);
    //     }        
    // }

}


class ProductMap 
{
    venueName:String
    productName:string
    productID:string
    categoryName:string
    formats:ProductFormatMap[] = []
}

class ProductFormatMap 
{
    id:string
    formatName:string
    formatID:string
    amount:number    
    productFormatPlace:ProductFormatPlace
}