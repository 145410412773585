class LicensesDetailViewController extends MUIViewController implements LicenseDetailInfoCellDelegate
{    
    static newInstance(): LicensesDetailViewController {
        let vc:LicensesDetailViewController;

        if (MIOCoreIsPhone() == true) {
            vc = new LicensesDetailViewController('licenses-detail-view_phone');
            vc.initWithResource('layout/licenses/LicensesDetailView_phone.html');                
        }
        else {
            vc = new LicensesDetailViewController('licenses-detail-view');
            vc.initWithResource('layout/licenses/LicensesDetailView.html');                    
        }

        return vc;
    }

    private saveButton:MUIButton = null;
    private addButton:MUIButton = null;
    private settingsButton:MUIButton = null;

    private nameLabel:MUILabel = null;
    private distributorDropdown:MUIButton = null;
    private chargeToDistributorSwitch: MUISwitchButton = null;
    private payMethodDropdown:MUIButton = null;
    private bankAccountDropdown:MUIButton = null;

    private contentView:MUIView = null;
    private appsViewController:LicensesDetailApplicationViewController = null;

    private detailSegmentedControl:MUISegmentedControl = null;
    private detailTableView:UITableView = null;
    private footerCommentView:MUIView = null;
    private commentTextArea:MUITextArea = null;
    private addCommentButton:MUIButton = null;

    private infoDataSource:LicensesDetailInfoDataSource = null;
    private logDataSource:BusinessLicenseLogDataSource = null;
    private tokenDataSource:LicensesDetailTokenDataSource = null;


    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, this.showAddLicenseAction);

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        });

        this.settingsButton = MUIOutlet( this, "settings-btn", "MUIButton" );
        this.settingsButton.setAction( this, function(this:LicensesDetailViewController) {
            this.showSettingActions()
        });

        this.nameLabel = MUIOutlet(this, "client-lbl", "MUILabel");

        this.distributorDropdown = MUIOutlet( this, "distributor-dd", "MUIButton");
        this.distributorDropdown.setAction(this, function(this:LicensesDetailViewController) {
            AppHelper.sharedInstance().showSelectDistributorViewControllerFromView( this.distributorDropdown, this.businessLicense.distributor, this, function(this:LicensesDetailViewController, controller:any, object:Distributor){
                this.businessLicense.distributor = object;
                this.businessLicense.distributorName = object?.name;                
                this.distributorDropdown.title = object?.name;
            });
        });

        this.chargeToDistributorSwitch = MUIOutlet( this, "switch-btn", "MUISwitchButton" );
        this.chargeToDistributorSwitch.setOnChangeValue( this, function(this:LicensesDetailViewController, control:MUISwitchButton, value:boolean){
            this.businessLicense.chargeToDistributor = value;
        });
        
        this.payMethodDropdown = MUIOutlet( this, "pay-method-dd", "MUIButton");
        this.payMethodDropdown.setAction(this, function(this:LicensesDetailViewController) {
            AppHelper.sharedInstance().showSelectPaymethodViewControllerFromView( this.payMethodDropdown, this.businessLicense.payMethod, this, function(this:LicensesDetailViewController, controller:any, object:PayMethod){
                this.businessLicense.payMethod = object;                
                this.payMethodDropdown.title = object.name;
            });
        });

        this.bankAccountDropdown = MUIOutlet( this, "bank-account-dd", "MUIButton");
        this.bankAccountDropdown.setAction(this, function(this:LicensesDetailViewController) {
            AppHelper.sharedInstance().showSelectBankInfoViewControllerFromView( this.bankAccountDropdown, this.businessLicense.client, this, function(this:LicensesDetailViewController, controller:any, object:BankAccountInfo){
                this.businessLicense.bankAccountInfo = object;                
                this.bankAccountDropdown.title = object.number;
            });
        });

        this.contentView = MUIOutlet(this, "content-view", "MUIView");
        this.appsViewController = LicensesDetailApplicationViewController.newInstance();
        this.appsViewController.delegate = this;
        this.contentView.addSubview(this.appsViewController.view);
        this.addChildViewController(this.appsViewController);


        this.detailSegmentedControl = MUIOutlet( this, "detail-segmented-control", "MUISegmentedControl" );
        this.detailSegmentedControl.setAction( this, function( this:LicensesDetailViewController, control:MUISegmentedControl, index:number ){
            this.reloadDetailTableView();
        } );        

        this.detailTableView = MUIOutlet(this,"detail-table-view", "UITableView");
        this.footerCommentView = MUIOutlet( this, "footer-comment-container", "MUIView" );
        this.commentTextArea = MUIOutlet( this, "comments-ta", "MUITextArea" );
        this.addCommentButton = MUIOutlet( this, "comment-add-log-btn", "MUIButton" );
        this.addCommentButton.setAction( this, this.addCommentAction );

        this.logDataSource = new BusinessLicenseLogDataSource();
        this.logDataSource.initWithTableView( this.detailTableView );

        this.infoDataSource = new LicensesDetailInfoDataSource();
        this.infoDataSource.initWithTableView( this.detailTableView, this );

        this.tokenDataSource = new LicensesDetailTokenDataSource();
        this.tokenDataSource.initWithTableView( this.detailTableView );

        this.detailTableView.dataSource = this.logDataSource;
        this.detailTableView.delegate = this.logDataSource;
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }
    
    private businessLicense:BusinessLicense = null;
    set item(item:BusinessLicense) {
        this.businessLicense = item;        
        this.updateUI();
    }

    private updateUI(){
        if(this.viewIsLoaded == false || this.businessLicense == null) return;
        
        this.nameLabel.text = this.businessLicense.client.name;
        this.distributorDropdown.title = this.businessLicense.distributorName;
        this.chargeToDistributorSwitch.on = this.businessLicense.chargeToDistributor;
        this.payMethodDropdown.title = this.businessLicense.payMethod?.name;
        this.bankAccountDropdown.title = this.businessLicense.bankAccountInfo?.number;
        this.logDataSource.item = this.businessLicense;

        this.appsViewController.item = this.businessLicense;
    }

    private reloadDetailTableView() {
        switch( this.detailSegmentedControl.selectedSegmentedIndex ) {
            case 0:
                this.detailTableView.dataSource = this.logDataSource;
                this.detailTableView.delegate = this.logDataSource;
                this.logDataSource.fetchedResultsController = null;
                this.footerCommentView.hidden = false;
                break;
            case 1: 
                this.detailTableView.dataSource = this.infoDataSource;
                this.detailTableView.delegate = this.infoDataSource;
                // this.tokenDataSource.fetchedResultsController = null;
                this.footerCommentView.hidden = true;
                break;

            case 2:
                this.detailTableView.dataSource = this.tokenDataSource;
                this.detailTableView.delegate = this.tokenDataSource;
                this.tokenDataSource.fetchedResultsController = null;
                this.footerCommentView.hidden = true;
                break;
        }

        this.detailTableView.reloadData();
    }

    private showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("License", NoItemSelectedViewControllerType.License);        
        this.splitViewController.showDetailViewController(vc);
    }

    private last_distributor_fee:string = null;

    private showAddLicenseAction() {
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("LICENSE","LICENSE"), MIOLocalizeString("ADD LICENSE","ADD LICENSE"), MUIAlertViewStyle.Default);

        avc.addComboBoxWithConfigurationHandler(this, function(control:MUIComboBox){            
            DBHelper.sharedInstance().addObserverForEntity(avc, 'LicenseProduct', MIOPredicate.predicateWithFormat("deletedAt = null"), [MIOSortDescriptor.sortDescriptorWithKey("name", true)], null, function (objects:LicenseProduct[]) {
                control.removeAllItems();
                let selectedIndex = 0;
                for (let i = 0; i < objects.length; i++){
                    let c = objects[i];
                    let price = (MUIWebApplication.sharedInstance().delegate as AppDelegate).currencyFormatter.stringFromNumber( c.price );
                    control.addItem(c.name + " ( " + price + " )", i);
                }
                control.selectItem(selectedIndex);
            });

            control.setOnChangeAction(this, function(cb:MUIComboBox, value:string){
                let index = parseInt( cb.getSelectedItem() );
                let product = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, index, 'LicenseProduct') as LicenseProduct;
                avc.textFields[0].placeholderText = (MUIWebApplication.sharedInstance().delegate as AppDelegate).currencyFormatter.stringFromNumber( product.price );
            });
        });

        avc.addComboBoxWithConfigurationHandler(this, function(control:MUIComboBox){            
            DBHelper.sharedInstance().addObserverForEntity(avc, 'LicenseType', MIOPredicate.predicateWithFormat("deletedAt = null"), [MIOSortDescriptor.sortDescriptorWithKey("name", true)], null, function (objects:LicenseProduct[]) {
                control.removeAllItems();
                let selectedIndex = 0;
                for (let i = 0; i < objects.length; i++){
                    let t = objects[i];                    
                    control.addItem(t.name, i);                    
                }
                control.selectItem(selectedIndex);
            });

            control.setOnChangeAction(this, function(cb:MUIComboBox, value:string){
                let index = parseInt( cb.getSelectedItem() );
                let product = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, index, 'LicenseProduct') as LicenseProduct;
                avc.textFields[0].placeholderText = (MUIWebApplication.sharedInstance().delegate as AppDelegate).currencyFormatter.stringFromNumber( product.price );
            });
        });

        avc.addTextFieldWithConfigurationHandler(this, function(control:MUITextField){
            control.placeholderText = MIOLocalizeString("PRICE","PRICE");
        });

        avc.addTextFieldWithConfigurationHandler(this, function(control:MUITextField){
            control.placeholderText = MIOLocalizeString("DISCOUNT","DISCOUNT");
        });

        avc.addTextFieldWithConfigurationHandler(this, function(this:LicensesDetailViewController, control:MUITextField){
            control.placeholderText = MIOLocalizeString("QUANTITY","QUANTITY");
            control.text = "1";
        });

        avc.addTextFieldWithConfigurationHandler(this, function(this:LicensesDetailViewController, control:MUITextField){
            control.placeholderText = MIOLocalizeString("RESELLER FEE","RESELLER FEE");
            if (this.last_distributor_fee != null) control.text = this.last_distributor_fee;
        });

        avc.addTextFieldWithConfigurationHandler(this, function(control:MUITextField){
            control.placeholderText = MIOLocalizeString("START DATE","START DATE");
        });

        avc.addTextFieldWithConfigurationHandler(this, function(control:MUITextField){
            control.placeholderText = MIOLocalizeString("END DATE","END DATE");
        });

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK","OK"), MUIAlertActionStyle.Default, this, function(this:LicensesDetailViewController){
            let productIndex = parseInt(avc.comboBoxes[0].getSelectedItem());
            let product = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, productIndex, 'LicenseProduct') as LicenseProduct;
            
            let typeIndex = parseInt(avc.comboBoxes[1].getSelectedItem());
            let type = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, typeIndex, 'LicenseType') as LicenseType;            

            let price = avc.textFields[0].text.length > 0 ? (MUIWebApplication.sharedInstance().delegate as AppDelegate).currencyFormatter.numberFromString(avc.textFields[0].text) : null;
            let discount = avc.textFields[1].text.length > 0 ? avc.textFields[1].text : null;
            let qty = parseInt( avc.textFields[2].text ) ?? 1;
            let resellerFee = avc.textFields[3].text;
            let startDate = avc.textFields[4].text;
            let endDate = avc.textFields[5].text;                        

            this.last_distributor_fee = resellerFee.length > 0 ? resellerFee : null; 

            this.createLicense(qty, product, type, price, discount, resellerFee, startDate, endDate );
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL","CANCEL"), MUIAlertActionStyle.Cancel, null, null) ); 
                
        avc.addCompletionHandler( this, function(){
            // Release the observers            
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc, 'LicenseProduct');
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc, 'LicenseType');
        });

        this.presentViewController(avc, true);
    }

    private createLicense(qty:number, product:LicenseProduct, type:LicenseType, price:number, discount:string, resellerFee:string, startDate:string, endDate:string) {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let start_date = ad.dateTimeFormatter.dateFromString(startDate);
        if (start_date == null) start_date = new Date();
        let end_date = ad.dateTimeFormatter.dateFromString(endDate);

        for (let index = 0; index < qty; index++) {

            let lic = MIOEntityDescription.insertNewObjectForEntityForName( "License", ad.managedObjectContext ) as License;
            lic.product = product;        
            lic.active = true;
            lic.startDate = start_date;
            lic.expirationDate = end_date;
            lic.type = type;
            lic.business = this.businessLicense;

            lic.price = price ?? product.price;
            
            let d = 0;
            if (discount != null) {
                d = discount.valueFromPercentageString( lic.price );
                lic.discountFormat = discount;
            }

            lic.total = lic.price - d;
            
            lic.distributorFeeFormat = resellerFee;
            lic.distributorFeeValue = resellerFee != null ? resellerFee.valueFromPercentageString( lic.total ) : 0;

            this.businessLicense.totalAmount += lic.total;
            this.businessLicense.distributorAmount += lic.distributorFeeValue;
        }
        
        DBHelper.saveMainContext();
    }


    private showSettingActions() {
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("OPTIONS","OPTIONS"), MIOLocalizeString("SELECT ONE OPTION BELOW","SELECT ONE OPTION BELOW"), MUIAlertViewStyle.Default);

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("GENERATE INVOICE","GENERATE INVOICE"), MUIAlertActionStyle.Default, this, function(this:LicensesDetailViewController){
            this.showInvoiceOptions();
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CHANGE PRODUCT FROM SELECTED LICENSE","CHANGE PRODUCT FROM SELECTED LICENSE"), MUIAlertActionStyle.Default, this, function(this:LicensesDetailViewController){
            this.changeProductLicense();
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("GENERATE TOKEN FROM SELECTED LICENSE","GENERATE TOKEN FROM SELECTED LICENSE"), MUIAlertActionStyle.Default, this, function(this:LicensesDetailViewController){
            this.showDatePicker( 6 );
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle("GENERATE TOKENS FROM DATE", MUIAlertActionStyle.Default, this, function(this:LicensesDetailViewController){
            this.showDatePicker( 8 );
        }));


        avc.addAction( MUIAlertAction.alertActionWithTitle("REMOVE ALL TOKENS/INSTALLMENTS LICENSE", MUIAlertActionStyle.Default, this, function(this:LicensesDetailViewController){
            this.removeAllTockens();
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("SET START DATE","SET START DATE"), MUIAlertActionStyle.Default, this, function(this:LicensesDetailViewController){
            this.showDatePicker( 1 );
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("SET RENOVATION DATE","SET RENOVATION DATE"), MUIAlertActionStyle.Default, this, function(this:LicensesDetailViewController){
            this.showDatePicker( 2 );
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("SET START & RENOVATION DATE","SET START & RENOVATION DATE"), MUIAlertActionStyle.Default, this, function(this:LicensesDetailViewController){
            this.showDatePicker( 3 );
        }));

        // avc.addAction( MUIAlertAction.alertActionWithTitle("CREATE TOKENS AND INSTALLMENTS", MUIAlertActionStyle.Default, this, function(this:LicensesDetailViewController){
        //     this.showDatePicker( 4 );
        // }));

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("UPDATE TOKENS PRICE","UPDATE TOKENS PRICE"), MUIAlertActionStyle.Default, this, function(this:LicensesDetailViewController){
            this.showDatePicker( 5 );
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle("REGENERATE LICENSE INVOICE", MUIAlertActionStyle.Default, this, function(this:LicensesDetailViewController){
            this.showUpdateInvoiceAlert();
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CHANGE BUSINESS NAME","CHANGE BUSINESS NAME"), MUIAlertActionStyle.Default, this, function(this:LicensesDetailViewController){
            this.showChangeBusinessNameAlert();
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CHANGE CLIENT","CHANGE CLIENT"), MUIAlertActionStyle.Default, this, function(this:LicensesDetailViewController){
            AppHelper.sharedInstance().showSelectClientViewControllerFromView(null, null, true, this, function(controller:SelectViewController, value:Client) {
                this.businessLicense.client = value;
            }, null, null );
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("RECALCULE TOTALS","RECALCULE TOTALS"), MUIAlertActionStyle.Default, this, function(this:LicensesDetailViewController){
            this.recalculateTotals();
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("DEACTIVATE CLIENT","DEACTIVATE CLIENT"), MUIAlertActionStyle.Destructive, this, function(this:LicensesDetailViewController){
            this.deactivateClient();
        }));

        if ((MUIWebApplication.sharedInstance().delegate as AppDelegate).selectedUser.isAdmin) {

            avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("DELETE SELECTED BUSINESS","DELETE SELECTED BUSINESS"), MUIAlertActionStyle.Destructive, this, function(this:LicensesDetailViewController){
                this.deleteBusiness();
            }));
        }

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController( avc, true);
    }

    private deleteBusiness(){        

        let avc = new MUIAlertViewController();
        avc.initWithTitle( MIOLocalizeString( "WARNING", "WARNING"), MIOLocalizeString( "ARE YOU SURE?", "ARE YOU SURE?" ), MUIAlertViewStyle.Default );

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString("DELETE", "DELETE"), MUIAlertActionStyle.Destructive, this, function(this:LicensesDetailViewController){
            MIONotificationCenter.defaultCenter().postNotification( "BusinessLicenseDidDelete", this.businessLicense ); 
            DBHelper.deleteObjectFromMainContext( this.businessLicense, true );
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController( avc, true);                
    }

    private recalculateTotals() {
        let total = 0
        let fee = 0;

        for ( let index = 0; index < this.appsViewController.fetchedResultsController.fetchedObjects.length; index++ ){
            let lic = this.appsViewController.fetchedResultsController.fetchedObjects[index] as License;
            if ( lic.active == false ) continue;
            total += lic.total;
            fee += lic.distributorFeeValue;
        }

        this.businessLicense.totalAmount = total;
        this.businessLicense.distributorAmount = fee;

        //this.updateTotals();
        DBHelper.saveMainContext();
    }

    // Cell delegate
    didSelectLicense(license:License){
        this.infoDataSource.item = license;
        this.tokenDataSource.item = license;

        this.reloadDetailTableView();
    }


    didChangePrice(license:License, price:number){
        license.price = price;
        let d = license.discountFormat?.valueFromPercentageString(price) ?? 0;
        this.didChangeTotal(license, price - d);
    }

    didChangeDiscount(license: License, discount: string): void {
        license.discountFormat = discount;
        let d = discount?.valueFromPercentageString(license.price) ?? 0;
        this.didChangeTotal(license, license.price - d);
    }

    didChangeTotal(license:License, total:number){

        this.businessLicense.totalAmount -= license.total;
        this.businessLicense.distributorAmount -= license.distributorFeeValue;

        license.total = total;         
        license.distributorFeeValue = license.distributorFeeFormat != null ? license.distributorFeeFormat.valueFromPercentageString( total ) : 0;

        this.businessLicense.totalAmount += license.total;
        this.businessLicense.distributorAmount += license.distributorFeeValue;

        this.appsViewController.updateTotals();
    }

    didChangeFee(license:License, fee:string){
        license.distributorFeeFormat = fee;
        
        this.businessLicense.distributorAmount -= license.distributorFeeValue;
        
        license.distributorFeeValue = license.distributorFeeFormat != null ? license.distributorFeeFormat.valueFromPercentageString( license.total ) : 0;

        this.businessLicense.distributorAmount += license.distributorFeeValue;
        this.appsViewController.updateTotals();
    }    

    private showInvoiceOptions(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("FIRST INVOICE OPTIONS","FIRST INVOICE OPTIONS"), MIOLocalizeString("CHOOSE WHOM TO CHARGE","CHOOSE WHOM TO CHARGE"), MUIAlertViewStyle.Default);

        avc.addComboBoxWithConfigurationHandler( this, function( comboBox:MUIComboBox ) { 
            comboBox.addItem( MIOLocalizeString("CUSTOMER", "CUSTOMER"), 0 );
            comboBox.addItem( MIOLocalizeString("DISTRIBUTOR", "DISTRIBUTOR"), 1 );
        } );

        avc.addTextFieldWithConfigurationHandler( this, function( textField:MUITextField ) {
            textField.placeholderText = MIOLocalizeString( "DATE", "DATE" );
        } );

        avc.addComboBoxWithConfigurationHandler( this, function( comboBox:MUIComboBox ) {
            let predicate = MIOPredicate.predicateWithFormat( "entityNameType = 'LicenseInvoice' and entityType >= 0" );
            let sds = [MIOSortDescriptor.sortDescriptorWithKey("prefix", false)];
            DBHelper.sharedInstance().addObserverForEntity(avc, "NumberSerialSequence", predicate, sds, null, function (objects:NumberSerialSequence[]) {
                comboBox.removeAllItems();
                comboBox.addItem( MIOLocalizeString ("DEFAULT", "DEFAULT" ), -1 );
                for ( let i = 0; i < objects.length; i++ )
                    comboBox.addItem( objects[i].name, i );
            });
        } );
        

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString( "OK", "OK" ), MUIAlertActionStyle.Default, this, function(this:LicensesDetailViewController){
            let chargeToDistributor = parseInt( avc.comboBoxes[0].getSelectedItem() ) == 0 ? false : true;

            let date = (MUIWebApplication.sharedInstance().delegate as AppDelegate).dateFormatter.dateFromString( avc.textFields[0].text );
            //let date = (MUIWebApplication.sharedInstance().delegate as AppDelegate).serverDateFormatter.dateFromString( avc.textFields[0].text );

            let serialIndex = parseInt( avc.comboBoxes[1].getSelectedItem() );
            let serial = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, serialIndex, 'NumberSerialSequence') as NumberSerialSequence;

            this.generateInvoice( chargeToDistributor, date, serial );
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString( "CANCEL", "CANCEL" ), MUIAlertActionStyle.Cancel, null, null));

        avc.addCompletionHandler(this, function () {
            // Release the observers
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc, 'NumberSerialSequence');
        });


        this.presentViewController( avc, true);
    }


    private generateInvoice( chargeToDistributor:boolean, date:Date, numberSerial?:NumberSerialSequence ){
        
        if ( date == null ) { 
            AppHelper.showErrorMessage( this, MIOLocalizeString("ERROR", "ERROR"), MIOLocalizeString("INVALID DATE. PLEASE ADD A VALID DATE", "INVALID DATE. PLEASE ADD A VALID DATE"));
            return;
        }

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.generateInvoice( this.businessLicense, chargeToDistributor, date, numberSerial, (data:any, error:any) => {
            if (error != null) {
                AppHelper.showErrorMessage(this, MIOLocalizeString("ERROR", "ERROR"), MIOLocalizeString("ERROR", "ERROR"));
                return;
            }

            AppHelper.showInfoMessage(this, MIOLocalizeString("INFO", "INFO"), MIOLocalizeString("THE INVOICE WAS CREATED", "THE INVOICE WAS CREATED"));
        });
    }

    private showDatePicker( tag:number ) {
        let dp = new MUIDatePickerController();
        dp.init();        
        dp.delegate = this;
        // dp.modalPresentationStyle = MUIModalPresentationStyle.PageSheet;
        dp.tag = tag;

        // let pc = dp.popoverPresentationController;
        // pc.sourceRect = this.settingsButton.frame;
        // pc.sourceView = this.settingsButton;

        AppHelper.sharedInstance().presentViewController(dp, true);
    }

    didSelectDate(controller:MUIDatePickerController, date:Date){
        switch( controller.tag ) {
            case 4: this.createTokensAndInstallments( date ); break;
            case 5: this.updateTokensPrice( date ); break;
            case 6: this.createTokenFromLicense( date ); break;
            case 8: this.createTokensFromBusiness( date ); break;
            case 7: this.updateInvoice( date ); break;
            default: this.applyDate( date, controller.tag ); break;
        }
    }

    private applyDate( date:Date, tag:number ) {

        let new_date = date;
        new_date.setHours(8);

        for (let index = 0; index < this.appsViewController.fetchedResultsController.fetchedObjects.length; index++) {
            let l = this.appsViewController.fetchedResultsController.fetchedObjects.objectAtIndex( index ) as License;            

            switch(tag) {
                case 1: l.startDate       = new_date; break;
                case 2: l.nextRenewalDate = new_date; break;
                case 3: 
                    l.startDate = date;
                    l.nextRenewalDate = date;
                    break;                 
            }
        }

        this.detailTableView.reloadData();
        this.appsViewController.tableView.reloadData();
    }

    private showChangeBusinessNameAlert() {
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("OPTIONS","OPTIONS"), MIOLocalizeString("TYPE THE NEW NAME","TYPE THE NEW NAME"), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler( this, function(this:LicensesDetailViewController, textField:MUITextField) {
            textField.placeholderText = MIOLocalizeString( "NAME", "NAME" );
        });

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK", "OK"), MUIAlertActionStyle.Default, this, function(this:LicensesDetailViewController){
            this.businessLicense.name = avc.textFields[0].name;
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController( avc, true);
    }

    private createTokensAndInstallments(date:Date){                

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.createTokensAndInstallments( this.businessLicense, date, (data:any, error:any) => {
            if (error != null) {
                AppHelper.showErrorMessage(this, MIOLocalizeString("ERROR", "ERROR"), error );
                return;
            }

            AppHelper.showInfoMessage(this, MIOLocalizeString("INFO", "INFO"), MIOLocalizeString("THE RESULT WAS OK", "THE RESULT WAS OK"));
        });
    }

    private updateTokensPrice(date:Date){                

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.updateTokensPrice( this.businessLicense, date, (data:any, error:any) => {
            if (error != null) {
                AppHelper.showErrorMessage(this, MIOLocalizeString("ERROR", "ERROR"), error );
                return;
            }

            AppHelper.showInfoMessage(this, MIOLocalizeString("INFO", "INFO"), MIOLocalizeString("THE RESULT WAS OK", "THE RESULT WAS OK"));
        });
    }

    private showUpdateInvoiceAlert() {
        let avc = new MUIAlertViewController();
        avc.initWithTitle("OPTIONS", "FILL THE INFO", MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler( this, function(this:LicensesDetailViewController, textField:MUITextField) {
            textField.placeholderText = MIOLocalizeString( "INVOICE NUMBER", "INVOICE NUMBER" );
        });

        avc.addTextFieldWithConfigurationHandler( this, function(this:LicensesDetailViewController, textField:MUITextField) {
            textField.placeholderText = MIOLocalizeString( "TOKEN START DATE", "TOKEN START DATE" );
        });

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK", "OK"), MUIAlertActionStyle.Default, this, function(this:LicensesDetailViewController){
            let doc_number = avc.textFields[0].text;
            let token_date = avc.textFields[1].text;
            let date = (MUIWebApplication.sharedInstance().delegate as AppDelegate).dateFormatter.dateFromString( token_date );
            this.regenerate_invoice( doc_number, date );
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController( avc, true);
    }

    private regenerate_invoice(invoice_number:string, date:Date){

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.regenerateInvoice( invoice_number, this.businessLicense, date, (data:any, error:any) => {
            if (error != null) {
                AppHelper.showErrorMessage(this, MIOLocalizeString("ERROR", "ERROR"), error );
                return;
            }

            AppHelper.showInfoMessage(this, MIOLocalizeString("INFO", "INFO"), MIOLocalizeString("THE RESULT WAS OK", "THE RESULT WAS OK"));
        });
    }


    private createTokenFromLicense( date: Date ) {
        if (this.appsViewController.selectedItem == null) return;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.createTokenFromLicense( this.appsViewController.selectedItem, date, (data:any, error:any) => {
            if (error != null) {
                AppHelper.showErrorMessage(this, MIOLocalizeString("ERROR", "ERROR"), error );
                return;
            }
            
            AppHelper.showInfoMessage(this, MIOLocalizeString("INFO", "INFO"), MIOLocalizeString("THE RESULT WAS OK", "THE RESULT WAS OK"));
        } );
    }

    private createTokensFromBusiness( date: Date ) {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.createTokensFromBusiness( this.businessLicense, date, (data:any, error:any) => {
            if (error != null) {
                AppHelper.showErrorMessage(this, MIOLocalizeString("ERROR", "ERROR"), error );
                return;
            }
            
            AppHelper.showInfoMessage(this, MIOLocalizeString("INFO", "INFO"), MIOLocalizeString("THE RESULT WAS OK", "THE RESULT WAS OK"));
        } );
    }


    private changeProductLicense() {        
        let vc = AppHelper.sharedInstance().selectViewController("LicenseProduct", "name", false, false, null, this) as SelectEntityViewController;
        vc.allowSearch = true;
     
        this.presentViewController( vc, true);
    }

    fetchRequestForController(controller:SelectEntityViewController):MIOFetchRequest{

        if (controller.identifier == "LicenseProduct"){            
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName( "LicenseProduct" ); //DBHelper.listFetchRequestWithEntityName("Country", sd, null);
            fetchRequest.sortDescriptors = sd;
            return fetchRequest;            
		}
    }

    didSelectObjectFromSelectViewController(controller:SelectEntityViewController, item:MIOManagedObject){

        if (controller.identifier == "LicenseProduct") {
            let object = item as LicenseProduct;            
            this.selectedItem.product = object;
        }

        return true;
    }

    private removeAllTockens() {
        
        DBHelper.queryObjectsWithCompletion( "LicenseToken", null, MIOPredicate.predicateWithFormat( "license.identifier == " + this.selectedItem.identifier ), ["installment"], this, function(this:LicensesDetailViewController, objects:LicenseToken[]){

            for (let obj of objects) {
                DBHelper.deleteObjectFromMainContext( obj, false );
            }
            
            DBHelper.saveMainContext();
        } );
    }

    private addCommentAction() {
        if ( this.commentTextArea.text.trim().length == 0 ) return;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let log = MIOEntityDescription.insertNewObjectForEntityForName( "BusinessLog", ad.managedObjectContext ) as BusinessLog;

        log.business = this.businessLicense;
        log.user = ad.selectedUser;
        log.userName = ad.selectedUser.name;
        log.date = new Date();
        log.message = this.commentTextArea.text;

        DBHelper.saveMainContext();

        this.commentTextArea.text = null;
    }

    private deactivateClient() {
        let vc = LeaveReasonViewController.newInstance()
        vc.business = this.businessLicense;
        this.presentViewController( vc, true );
    }

}