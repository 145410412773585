class ProductAdminListViewController extends BaseViewController
{
    static newInstance() : ProductAdminListViewController {
        let vc = new ProductAdminListViewController('product-admin-view');
        vc.initWithResource("layout/productadmin/ProductAdminView.html");
        return vc;
    }
    
    private segmentedControl:MUISegmentedControl = null;
    private saveButton:MUIButton = null;

    private productFilterHeader:MUIView = null;
    private productTableView:UITableView = null;

    private formatContainerView:MUIView = null;
    private formatFilterHeader:MUIView = null;
    private formatDetailFilterHeader:UIScrollView = null    
    private formatTableView:UITableView = null;
    private formatDetailTableView:UITableView = null;

    private productDataSource:ProductAdminDataSource = null;
    private formatDataSource:ProductFormatAdminDataSource = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.segmentedControl = MUIOutlet(this, "segmented-control", "MUISegmentedControl");
        this.segmentedControl.setAction(this, this.changeDataSourceAction);

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        });
        
        this.productFilterHeader = MUIOutlet(this, "products-filter-header", "MUIView");

        this.productTableView = MUIOutlet(this, "products-table-view", "UITableView");
        this.productDataSource = new ProductAdminDataSource();
        this.productDataSource.initWithTableView(this.productTableView, this.productFilterHeader, this);
        this.productTableView.dataSource = this.productDataSource;
        // this.productTableView.delegate = this.productDataSource;

        this.formatContainerView = MUIOutlet(this, "formats-container-view", "MUIView");
        this.formatFilterHeader = MUIOutlet(this, "formats-filter-header", "MUIView");
        this.formatTableView = MUIOutlet(this, "formats-table-view", "UITableView");
        this.formatDetailFilterHeader = MUIOutlet(this, "formats-repeat-filter-header", "UIScrollView");
        this.formatDetailTableView = MUIOutlet(this, "formats-repeat-table-view", "UITableView");

        this.formatDataSource = new ProductFormatAdminDataSource();
        this.formatDataSource.initWithTableView(this.formatTableView, this.formatFilterHeader, this.formatDetailTableView, this.formatDetailFilterHeader, this);
        // this.formatTableView.dataSource = this.formatDataSource;
        // this.formatTableView.delegate = this.formatDataSource;

        this.formatContainerView.hidden = true;
        // this.formatFilterHeader.hidden = true;
        // this.formatTableView.hidden = true;
        // this.formatDetailFilterHeader.hidden = true;
        // this.formatDetailTableView.hidden = true;

        const places = DBHelper.queryObjectsFromMainContext("Place", "deletedAt = null", null);
        let places_by_id = {};
        for (let p of places) {
            places_by_id[p.identifier] = p.name;
        }
        
        this.productDataSource.placesByID = places_by_id;
        this.formatDataSource.placesByID = places_by_id;

        this.productDataSource.reloadData();
    }

    private changeDataSourceAction(sender){
        switch(this.segmentedControl.selectedSegmentedIndex){
            case 0:
                this.productFilterHeader.hidden = false;
                this.productTableView.hidden = false;
                this.formatContainerView.hidden = true;
                this.productDataSource.reloadData();
                break;

            case 1:
                break;    

            case 2:
                this.productFilterHeader.hidden = true;
                this.productTableView.hidden = true;
                this.formatContainerView.hidden = false;
                this.formatDataSource.reloadData();
                break;                
        }        
    }
}