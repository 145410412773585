class AdyenDetailCell extends UITableViewCell
{
    protected retryButton:MUIButton = null;
    protected statusIcon:MUIView = null;

    awakeFromHTML(){
        this.retryButton = MUIOutlet(this, "cell-retry-btn", "MUIButton");
        this.statusIcon = MUIOutlet(this, "cell-status-icon", "MUIView");
        this.selectionStyle = UITableViewCellSelectionStyle.None;
        this._update_ui();
    }

    protected _company:Company = null;
    set company(company:Company){
        this._company = company;
        this._update_ui();
    }

    protected _payment_entity:AdyenPaymentEntity = null;
    set paymentEntity(paymentEntity:AdyenPaymentEntity){
        this._payment_entity = paymentEntity;
        this._update_ui();
    }

    protected _update_ui(){
        if (this._payment_entity == null || this._company == null) return;
        this.updateUI();
    }

    protected updateUI(){}
}


class AdyenLegalEntityDetailCell extends AdyenDetailCell
{
    private legalDocumentLabel:MUILabel = null;
    private vatDocumentLabel:MUILabel = null;
    private legalTypeComboBox:MUIComboBox = null;

    private addressLabel:MUILabel = null;
    private cityLabel:MUILabel = null;
    private postalCodeLabel:MUILabel = null;
    private countryLabel:MUILabel = null;

    private venueAddressTextField:MUITextField = null;
    private venueCityTextField:MUITextField = null;
    private venuePostalCodeTextField:MUITextField = null;
    private venueCountryLabel:MUILabel = null;

    awakeFromHTML()
    {
        super.awakeFromHTML();
        this.legalDocumentLabel = MUIOutlet(this, "registration-number-tf", "MUILabel");
        this.vatDocumentLabel = MUIOutlet(this, "vat-number-tf", "MUILabel");
        this.legalTypeComboBox = MUIOutlet(this, "legal-entity-type-cb", "MUIComboBox");
        this.legalTypeComboBox.setOnChangeAction(this, function(this:AdyenLegalEntityDetailCell, control:MUIComboBox, value:string){
            this._payment_entity.companyType = parseInt(value);
        });

        this.addressLabel = MUIOutlet(this, "address-lbl", "MUILabel");
        this.cityLabel = MUIOutlet(this, "city-lbl", "MUILabel");
        this.postalCodeLabel = MUIOutlet(this, "postal-code-lbl", "MUILabel");
        this.countryLabel = MUIOutlet(this, "country-lbl", "MUILabel");

        this.venueAddressTextField = MUIOutlet(this, "venue-address-tf", "MUITextField");
        this.venueCityTextField = MUIOutlet(this, "venue-city-tf", "MUITextField");
        this.venuePostalCodeTextField = MUIOutlet(this, "venue-postal-code-tf", "MUITextField");
        this.venueCountryLabel = MUIOutlet(this, "venue-country-tf", "MUILabel");

        this.venueAddressTextField.enabled = false;
        this.venueCityTextField.enabled = false;
        this.venuePostalCodeTextField.enabled = false;
        this.venueCountryLabel.enabled = false;
    }

    protected updateUI(): void 
    {        
        this.legalDocumentLabel.text = this._company.document;
        this.vatDocumentLabel.text = this._company.address.countryISOa2 + this._company.document;
        this.legalTypeComboBox.selectItem( this._payment_entity.companyType );         

        this.addressLabel.text = this._company.address.address1;
        this.cityLabel.text = this._company.address.city;
        this.postalCodeLabel.text = this._company.address.postalCode;
        this.countryLabel.text = this._company.address.countryISOa2;

        this.venueAddressTextField.text = this._company.address.address1;
        this.venueCityTextField.text = this._company.address.city;
        this.venuePostalCodeTextField.text = this._company.address.postalCode;
        this.venueCountryLabel.text = this._company.address.countryISOa2;

        let is_created = ( this._payment_entity.config != null && this._payment_entity.config[ "LegalEntityID" ] != null );
        if ( is_created ) {
            this.legalTypeComboBox.enabled = false;
            this.retryButton.hidden = true;
            MUICoreLayerAddStyle( this.statusIcon.layer, "active" );
            MUICoreLayerRemoveStyle( this.statusIcon.layer, "alert" );
        }
    }

}

class AdyenAccountHolderDetailCell extends AdyenDetailCell
{
    private legalEntityIDLabel:MUILabel = null;
    private timeZoneLabel:MUILabel = null;

    awakeFromHTML(){
        super.awakeFromHTML();
        this.legalEntityIDLabel = MUIOutlet(this, "legal-entity-id-lbl", "MUILabel");
        this.timeZoneLabel = MUIOutlet(this, "time-zone-lbl", "MUILabel");
    }

    protected updateUI(): void {
        this.legalEntityIDLabel.text = this._payment_entity.config != null ? this._payment_entity.config[ "LegalEntityID" ] : null;
        this.timeZoneLabel.text = SettingsHelper.sharedInstance().config[ "timezone" ];
    }
}

class AdyenBuinessLineDetailCell extends AdyenDetailCell
{
    private businessLineLabel:MUILabel = null;

    awakeFromHTML()
    {
        super.awakeFromHTML();
        // this.businessLineLabel = MUIOutlet(this, "business-line-lbl", "MUILabel");
    }

    protected updateUI(): void {
        // this.businessLineLabel.text = this._company.businessLine;
    }
}

class AdyenBalanceDetailCell extends AdyenDetailCell
{
    private balanceLabel:MUILabel = null;

    awakeFromHTML()
    {
        super.awakeFromHTML();
        // this.balanceLabel = MUIOutlet(this, "balance-lbl", "MUILabel");
    }

    protected updateUI(): void {
        // this.balanceLabel.text = this._payment_entity.balance;
    }
}

class AdyenStoreDetailCell extends AdyenDetailCell
{
    // private storeNameLabel:MUILabel = null;

    awakeFromHTML()
    {
        super.awakeFromHTML();
        // this.storeNameLabel = MUIOutlet(this, "store-name-lbl", "MUILabel");
    }

    protected updateUI(): void {
        // this.storeNameLabel.text = this._company.name;
    }
}

class AdyenActionsDetailCell extends AdyenDetailCell
{
    private signUpButton:MUIButton = null;    
    private reviewLinkButton:MUIButton = null;    


    awakeFromHTML(): void {
        this.reviewLinkButton = MUIOutlet(this, "review-link-btn", "MUIButton");
        this.reviewLinkButton.setAction(this, this.reviewLinkAction);

        this.signUpButton = MUIOutlet(this, "sign-up-btn", "MUIButton");
        this.signUpButton.setAction(this, this.signUp);
    }

    private signUp(){
        this.signUpButton.enabled = false;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.adyenSignUp( (error) => {
            this.signUpButton.enabled = true;
            if (error != null) {
                AppHelper.sharedInstance().showError(error);
                return;
            }

            this.signUpButton.enabled = false;
            // this.reviewLinkButton.enabled = true;
        });
    }

    private reviewLinkAction()
    {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.adyenHOPLink( (json, error) => {
            const url = json["url"];
            // let vc = new AdyenHOPLinkViewController();
            // vc.init();
            // vc.url = url;
            // AppHelper.sharedInstance().presentViewController( vc, true );
            window.open(url,'_blank');
        });
    }
}