
/// <reference path = "../model/ProductListItem.ts" />

class DBHelper extends MIOObject {

    private static _sharedInstance: DBHelper = null;
    static sharedInstance(): DBHelper {

        if (this._sharedInstance == null) {
            this._sharedInstance = new DBHelper();
            this._sharedInstance.init();
        }

        return this._sharedInstance;
    }

    constructor() {
        super();
        if (DBHelper._sharedInstance) {
            throw new Error("DBHelper: Instantiation failed: Use sharedInstance() instead of new.");
        }
    }

    static get mainManagedObjectContext():MIOManagedObjectContext{
        return MUIWebApplication.sharedInstance().delegate.managedObjectContext;
    }

    static saveMainContext(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.managedObjectContext.save();
    }

    static saveMainContextWithCompletion(target, completion){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webPersistentStore.performBlockWithCompletion(target, function(){
            ad.managedObjectContext.save();
        }, completion);
    }

    static deleteObjectFromMainContext(object:MIOManagedObject, save:boolean){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.managedObjectContext.deleteObject(object);
        if (save == true) ad.managedObjectContext.save();
    }

    static listFetchRequestWithEntityName(entityName, sortsDescriptors, predicateFormat, fetchDeleted?: boolean, limit?:number) {

        let request = MIOFetchRequest.fetchRequestWithEntityName(entityName);
        request.sortDescriptors = sortsDescriptors;
        if(limit != null) request.fetchLimit = limit;

        if (fetchDeleted == true) {
            request.predicate = (predicateFormat?.length > 0) ? MIOPredicate.predicateWithFormat(predicateFormat) : null;
        }
        else {
            request.predicate = (predicateFormat?.length > 0) ? 
                  MIOPredicate.predicateWithFormat("deletedAt == null AND (" + predicateFormat + ")")
                : MIOPredicate.predicateWithFormat("deletedAt == null");
                
        }

        return request;
    }

    static queryObjects(context: MIOManagedObjectContext, entityName: string, predicate?: MIOPredicate, includeEntities?) {

        let request = MIOFetchRequest.fetchRequestWithEntityName(entityName);

        if (predicate != null)
            request.predicate = predicate;

        if (includeEntities != null)
            request.relationshipKeyPathsForPrefetching = includeEntities;

        let moc:MIOManagedObjectContext = context != null ? context : MUIWebApplication.sharedInstance().delegate.managedObjectContext;

        let objs = moc.executeFetch(request);
        return objs;
    }

    static queryObject(context: MIOManagedObjectContext, entityName: string, predicate?: MIOPredicate, includeEntities?) {

        let objs = DBHelper.queryObjects(context, entityName, predicate, includeEntities);
        return objs.length > 0 ? objs[0] : null;
    }

    static queryObjectsFromMainContext(entityName: string, sortDescriptors?:any, predicate?: MIOPredicate, includeEntities?:any) {
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        return DBHelper.queryObjects(ad.managedObjectContext, entityName, predicate, includeEntities);
    }

    static queryObjectFromMainContext(entityName: string, predicate?: MIOPredicate, includeEntities?) {

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        return DBHelper.queryObject(ad.managedObjectContext, entityName, predicate, includeEntities);
    }

    static queryObjectsWithCompletion(entityName: string, sortDescriptors:any, predicate: MIOPredicate, includeEntities, target, completion) {
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        let wps:MWSPersistentStore = ad.webPersistentStore;
        let moc:MIOManagedObjectContext = ad.managedObjectContext;
        
        let request = MIOFetchRequest.fetchRequestWithEntityName(entityName);
        request.sortDescriptors = sortDescriptors;
        request.predicate = predicate;
        request.relationshipKeyPathsForPrefetching = includeEntities;

        wps.fetchObjects(request, moc, target, completion);
    }

    static queryObjectWithCompletion(entityName: string, sortDescriptors:any, predicate: MIOPredicate, includeEntities, target, completion) {
        DBHelper.queryObjectsWithCompletion(entityName, sortDescriptors, predicate, includeEntities, this, function(objects){
            if (objects.length > 0) completion.call(target, objects[0])
            else completion.call(target, null)
        });        
    }

    static objectFromObjectID(objectID:MIOManagedObjectID, target, completion) {        
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        let wps:MWSPersistentStore = ad.webPersistentStore;
        let moc:MIOManagedObjectContext = ad.managedObjectContext;

        let obj = moc.existingObjectWithID(objectID);
        if (obj == null) {
            // Fetch
        }
        else {
            completion.call(target, obj);
        }        
    }
    
    private managedObjectContext = null;
    private fetchesByEntity = {};
    private observersByEntity = {};

    init() {
        super.init();
        let ad: AppDelegate = MUIWebApplication.sharedInstance().delegate;
        this.managedObjectContext = ad.managedObjectContext;
    }

    isObservingForChangesInEntity(observer, entityName: string):boolean{
        let array = this.observersByEntity[entityName];

        for (let index = 0; array != null && index < array.length; index++) {
            let i = array[index];
            let obs = i["OBS"];

            if (obs === observer) {
                return true;
            }
        }
        return false;
    }
    
    addObserverForEntity(observer, entityName: string, predicate: MIOPredicate, sortDescriptors, relationShips, completion) {

        let item = {};
        item["OBS"] = observer;
        item["Block"] = completion;
        item["Predicate"] = predicate;
        item["Sorts"] = sortDescriptors;

        let array = this.observersByEntity[entityName];
        if (array == null) {
            array = [];
            this.observersByEntity[entityName] = array;
            this.addFetchedWithEntity(entityName, relationShips);
        }

        // Check not add the same observer for the same entity
        for (let index = 0; index < array.length; index++) {
            let i = array[index];
            let obs = i["OBS"];

            if (obs === observer) {
                throw new Error("AppHelper: Trying to add the same observer for the same entity!");
            }
        }

        array.push(item);

        // Check for relationships
        let resetFecth = false;
        if (relationShips != null) {
            let rels = this.relationShipsByEntity[entityName];
            for(let index = 0; index < relationShips.length; index++){
                let r = relationShips[index];
                if (rels.containsObject(r) == false) {
                    rels.addObject(r);
                    resetFecth = true;
                }
            }

            if (resetFecth) {
                this.executeFetchedWithEntity(entityName);
            }
        }

        this.notifyObserverItem(item, entityName);
    }

    removeObserverForChangesInEntity(observer, entityName: string) {

        let array = this.observersByEntity[entityName];
        if (array == null) return;

        let indexFound = -1;
        for (let index = 0; index < array.length; index++) {
            let i = array[index];
            let obs = i["OBS"];

            if (obs === observer) {
                indexFound = index;
                break;
            }
        }

        if (indexFound > -1) {
            array.splice(indexFound, 1);
        }
    }

    objectsForEntity(observer, entityName: string) {
        
        let item = this.itemForObserverAndEntity(observer, entityName);
        let objs = item["Objects"];
        if (objs == null) {
            let fecth = this.fetchesByEntity[entityName];        
            objs = fecth.resultObjects;
    
            let predicate = item["Predicate"];
            let sorts = item["Sorts"];
        
            objs = _MIOPredicateFilterObjects(objs, predicate);
            objs = _MIOSortDescriptorSortObjects(objs, sorts);    

            item["Objects"] = objs;
        }
        
        return objs;
    }

    objectAtIndexForEntity(index, entityName:string){

        let fecth = this.fetchesByEntity[entityName];
        if (fetch == null) return null;
        
        let objs = fecth.resultObjects;

        if (objs == null) return null;
        if (objs.length == 0) return null;

        return objs[index];
    }

    objectAtIndexForEntityObserver(observer, index, entityName){
        
        let objs = this.objectsForEntity(observer, entityName);

        if (objs == null) return null;
        if (objs.length == 0) return null;

        return objs[index];
    }
    
    indexOfObjectForEntity(observer, entityName, object){
        let objects = this.objectsForEntity(observer, entityName);
        let index = objects.indexOf(object);
        
        if(index < 0) throw new Error('Object not found');
        return index;
    }

    setObserverPredicateForEntity(observer, entityName: string, predicate: MIOPredicate) {

        let item = this.itemForObserverAndEntity(observer, entityName);
        item["Predicate"] = predicate;
        delete item["Objects"]; // To reset the predicate
        
        this.notifyObserverItem(item, entityName);
    }

    private itemForObserverAndEntity(observer, entityName: string) {

        let array = this.observersByEntity[entityName];
        if (array == null) return null;

        for (let index = 0; index < array.length; index++) {
            let i = array[index];
            let obs = i["OBS"];

            if (obs === observer) {
                return i;
            }
        }

        return null;
    }

    private fetchRequestByEntity(entityName: string){
        let request = DBHelper.listFetchRequestWithEntityName(entityName, null, null, false);        
        request.relationshipKeyPathsForPrefetching = this.relationShipsByEntity[entityName];   
        return request;
    }

    private relationShipsByEntity = {};
    private addFetchedWithEntity(entityName: string, relationShips) {
        
        if (relationShips == null) this.relationShipsByEntity[entityName] = [];
        else this.relationShipsByEntity[entityName] = relationShips;                    

        let request = this.fetchRequestByEntity(entityName);

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(request, this.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        this.fetchesByEntity[entityName] = fetchedResultsController;

        this.executeFetchedWithEntity(entityName);
        
    }

    private executeFetchedWithEntity(entityName:string){

        let request = this.fetchRequestByEntity(entityName);

        let fetchedResultsController = this.fetchesByEntity[entityName] as MIOFetchedResultsController;
        fetchedResultsController.fetchRequest = request;
        
        fetchedResultsController.performFetch();
    }

    private notifyObserverItem(item, entityName:string){
        let observer = item["OBS"];
        let completion = item["Block"];
        let objects = this.objectsForEntity(observer, entityName);
        completion.call(observer, objects);
    }

    controllerDidChangeContent(controller: MIOFetchedResultsController) {

        let entityName = controller.fetchRequest.entityName;

        let observers = this.observersByEntity[entityName];
        for (let index = 0; index < observers.length; index++) {
            let item = observers[index];
            delete item["Objects"]; // To reset the predicate

            this.notifyObserverItem(item, entityName);
        }
    }
    
    static createPaymentEntity(name:string, type:PaymentEntityType, env:string, save?:boolean ) : PaymentEntity {        
        
        let entity_name = "PaymentEntity";
        switch (type) {
            case PaymentEntityType.CashOnDelivery: entity_name = "CashOnDeliveryPaymentEntity"; break;
            case PaymentEntityType.Adyen: entity_name = "AdyenPaymentEntity"; break;
        }
        
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext as MIOManagedObjectContext;
        let object = MIOEntityDescription.insertNewObjectForEntityForName( entity_name, moc) as PaymentEntity;

        object.name = name;
        // object.paymentType = type;
        object.environment = env;

        if (save == true) moc.save();

        return object;
    }

    // static createCategory(category) {
    //     let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
    //     let categoryListItem: ProductListItem = MIOEntityDescription.insertNewObjectForEntityForName("ProductListItem", moc) as ProductListItem;
    //     categoryListItem.identifier = MIOUUID.UUID().UUIDString;
    //     categoryListItem.type = ProductListItemType.Category;
    //     categoryListItem.orderIndex = 1;
    //     categoryListItem.itemID = category.identifier;
    //     categoryListItem.name = category.name;        
    //     categoryListItem.parentCategoryID = category.parentCategory;        
    // }

    static createSalesCategory(name:string, tax:Tax, parentCategory:ProductCategory, completion:any) {

        DBHelper.queryObjectWithCompletion("ProductCategory", null, MIOPredicate.predicateWithFormat("name = '" + name + "' and deletedAt = null"), [], this, function(object:StockCategory){
            if (object != null) {
                AppHelper.showErrorMessage(null, "ERROR", "THE SALES CATEGORY ALREADY EXISTS");
                return;
            }

            let moc: MIOManagedObjectContext = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
            let category = MIOEntityDescription.insertNewObjectForEntityForName("ProductCategory", moc) as ProductCategory;
            
            category.name = name;
            category.tax = tax;
            category.superCategory = parentCategory;  
            
            moc.save();

            if (completion) completion(category);
        });
    }

    static createStockCategory(name:string, parentCategory:StockCategory) {

        DBHelper.queryObjectWithCompletion("StockCategory", null, MIOPredicate.predicateWithFormat("name = '" + name + "'"), [], this, function(object:StockCategory){
            if (object != null) {
                AppHelper.showErrorMessage(null, "ERROR", "THE STOCK CATEGORY ALREADY EXISTS");
                return;
            }

            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext as MIOManagedObjectContext;

            let category = MIOEntityDescription.insertNewObjectForEntityForName("StockCategory", moc) as StockCategory;
            category.identifier = MIOUUID.UUID().UUIDString;
            category.name = name;
            category.superCategory = parentCategory;

            moc.save();
        });

    }

    static createProduct(name:string, category:ProductCategory, stockCagetory:StockCategory, measureType:MeasureUnitType, containerMeasureType:MeasureUnitType, containerQuantity:number, classname:string, completion:any) {

        DBHelper.queryObjectWithCompletion("Product", null, MIOPredicate.predicateWithFormat("name = '" + name.replace("'","\'") + "' and deletedAt = null"), [], this, function(object:StockCategory){
            if (object != null) {
                AppHelper.showErrorMessage(null, "ERROR", "THE PRODUCT ALREADY EXISTS");
                return;
            }

            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext as MIOManagedObjectContext;

            let product = MIOEntityDescription.insertNewObjectForEntityForName( classname, moc ) as Product;
            product.identifier = MIOUUID.UUID().UUIDString;
            product.name = name;
            product.category = category;
            product.stockCategory = stockCagetory;
            product.defaultWarehouse = AppHelper.sharedInstance().defaultWarehouse;
            product.defaultWarehouseName = product.defaultWarehouse?.name;

            if (measureType == MeasureUnitType.Container) {
                product.measureUnitType = containerMeasureType;
                product.quantity = containerQuantity;
            }
            else {
                product.measureUnitType = measureType;
                product.quantity = null;
            }

            if (completion) completion(product);
            
        });        
    }

    static createSalesProduct(name:string, tax:Tax, price:number, category:ProductCategory, classname:string = "Product", completion:any) {

        DBHelper.createProduct(name, category, null, MeasureUnitType.Unit, MeasureUnitType.None, 0, classname, (product:Product) => {

            product.tax = tax;
            product.price = price;
            product.isEnableForSell = SettingsHelper.sharedInstance().configurationBoolValue("is-sales-enable-on-create-sales-product", true);
            product.isEnableForStock = SettingsHelper.sharedInstance().configurationBoolValue("is-stock-enable-on-create-sales-product", false);

            DBHelper.saveMainContext();

            if (completion != null) completion( product );
        });
    }

    static createStockProduct(name:string, tax:Tax, stockCategory:StockCategory, warehouse:Warehouse, measrueType:MeasureUnitType, containerMeasureType:MeasureUnitType, containerQuantity:number, target:any, completion:any) {

        DBHelper.createProduct(name, null, stockCategory, measrueType, containerMeasureType, containerQuantity, "Product", (product:Product) => {

            product.taxBuy = tax;
            product.isEnableForSell = SettingsHelper.sharedInstance().configurationBoolValue("is-sales-enable-on-create-stock-product", false);
            product.isEnableForStock = SettingsHelper.sharedInstance().configurationBoolValue("is-stock-enable-on-create-stock-product", true);

            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext as MIOManagedObjectContext;

            let pw: ProductWarehouse = MIOEntityDescription.insertNewObjectForEntityForName("ProductWarehouse", moc) as ProductWarehouse;                
            pw.warehouse = warehouse;
            pw.isDefault = true;
            pw.product = product;            

            moc.save();

            if (completion != null) completion.call(target, product);
        });
    }

    //Create EconomicAccountLine used for Client Acount page
    createEconomicLine(value:number, paymethod: PayMethod, client:Client, type?:AccountLineType, booking?:Booking) {
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

        let economicLine = MIOEntityDescription.insertNewObjectForEntityForName("EconomicAccountLine", moc) as EconomicAccountLine;
        economicLine.identifier = MIOUUID.UUID().UUIDString;
        economicLine.economicEntity = client; // Economic entity
        economicLine.concept = (value < 0 ? 'Remove ' : 'Add ') + ' funds to ' + client.name;
        economicLine.value = value;
        economicLine.payMethod = paymethod;
        if (paymethod != null) economicLine.payMethodName = paymethod.name;
        economicLine.date = new Date();        
        if (type != null) economicLine.type = type;
        if (booking != null) economicLine.booking = booking;
        client.addEconomicAccountLinesObject(economicLine);
        //client.totalAccount += value;
    
        moc.save();
        return economicLine;
    }

    //Create LoyaltyAccount Line used for Loyalty page (need to revise)
    createLoyaltyLine(value, client:Client, type?:AccountLineType, bookingID?) {
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        let paymethod = null;

        let loyaltyLine = MIOEntityDescription.insertNewObjectForEntityForName("LoyaltyAccountLine", moc) as LoyaltyAccountLine;
        loyaltyLine.identifier = MIOUUID.UUID().UUIDString;
        loyaltyLine.economicEntity = client; //Economic entity
        loyaltyLine.concept = (value < 0 ? 'Remove ' : 'Add ') + ' funds to ' + client.name;
        loyaltyLine.value = value;
        // loyaltyLine.payMethod = paymethod;
        // if (paymethod != null) loyaltyLine.payMethodName = paymethod.name;
        loyaltyLine.date = new Date();        
        // if (type != null) loyaltyLine.type = type;
        // if (bookingID != null) loyaltyLine.bookingID = bookingID;
        client.addLoyaltyAccountLinesObject(loyaltyLine);
        //client.totalLoyalty += value;
    
        moc.save();
        return loyaltyLine;
    }

    static checkClient(name:string, target, completion){
        DBHelper.queryObjectsWithCompletion("Client", null, MIOPredicate.predicateWithFormat("name == '" + name + "'"), [], this, function(objects){
            if (objects.length > 0) completion.call(target, false);
            else completion.call(target, true);
        });
    }

    static createClient(name?:string){
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        let client = MIOEntityDescription.insertNewObjectForEntityForName("Client", moc) as Client;        
        client.address = MIOEntityDescription.insertNewObjectForEntityForName("Address", moc) as Address;
        client.phone = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", moc) as PhoneNumber;
        client.mobilePhone = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", moc) as PhoneNumber;
        client.type = 'C';

        if (name != null) client.name = name;


        return client;
    }

    static createSupplier(name:string):Supplier {
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        let supplier = MIOEntityDescription.insertNewObjectForEntityForName("Supplier", moc) as Supplier;        
        supplier.identifier = MIOUUID.UUID().UUIDString;
        supplier.type = 'S';

        supplier.name = name;

        return supplier;
    }

    static createEmployee(name:string):Employee {
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        let employee = MIOEntityDescription.insertNewObjectForEntityForName("Employee", moc) as Employee;        
        employee.identifier = MIOUUID.UUID().UUIDString;
        employee.type = 'W';

        employee.name = name;

        return employee;
    }


    addTipFromCashDeskLine(line:CashDeskLine, tip){
        this.createCashDeskLineTipFromLine(line, tip);
    }

    rectifyTipFromCashDeskLine(line:CashDeskLine, tip){
        let newTip = tip - line.money;
        this.createCashDeskLineTipFromLine(line, newTip);
    }

    createCashDeskLineTipFromLine(line:CashDeskLine, tip:number){

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let newLine = MIOEntityDescription.insertNewObjectForEntityForName("CashDeskLine", ad.managedObjectContext) as CashDeskLine;
                
        newLine.appID = line.appID;
        newLine.legalDocumentID = line.legalDocumentID;

        newLine.bookingID = line.bookingID;
        newLine.byUser = true;
        newLine.session = line.session;
        // newLine.operationID = line.operationID;
        newLine.checkPoint = line.checkPoint;
        newLine.comments = null;
        newLine.concept = MIOLocalizeString("TIP", "TIP");
        newLine.currency = null;
        // newLine.customConcept = null;
        newLine.date = line.date;        
        // newLine.debtLine = null;
        newLine.documentID = line.documentID;
        newLine.eventID = line.eventID;
        newLine.eventName = line.eventName;
        //newLine.externalTransactionID   
        newLine.info = null;
        // newLine.invitationResponsible = null;
        // newLine.invitationResponsibleName = null;
        // newLine.invitationResponsibleType = 0;
        newLine.legalDocumentID = null;
        newLine.locationCategoryName = line.locationCategoryName;
        newLine.locationName = line.locationName;
        newLine.money = tip;
        newLine.moneyOtherCurrency = null;
        newLine.payMethod = line.payMethod;
        newLine.payMethodName = line.payMethodName;
        newLine.payMethodSubtype = line.payMethodSubtype;
        newLine.paymentType = 1;
        //subCashDesk = nil;
        //tags = nil;
        //tax = nil;
        //taxID = nil;
        //taxName = nil;
        newLine.type = 10;
        // newLine.worker = line.worker;
        newLine.workerName = line.workerName;

        DBHelper.saveMainContext();
    }

    //temporary method for TicketView, should be implemented in API
    static createCashDeskLinePAXFromLine(line:CashDeskLine, pax){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let newLine:CashDeskLine = MIOEntityDescription.insertNewObjectForEntityForName("CashDeskLine", ad.managedObjectContext) as CashDeskLine;
                
        newLine.identifier = MIOUUID.UUID().UUIDString;
        // newLine.idApp = line.idApp;
        newLine.legalDocumentID = line.legalDocumentID;

        newLine.money = pax;
        newLine.type = 19;
        newLine.concept = MIOLocalizeString("PAX", "PAX");

        // newLine.idPlace = line.idPlace;
        newLine.bookingID = line.bookingID;
        newLine.byUser = false;
        // newLine.operationID = line.operationID;
        newLine.checkPoint = line.checkPoint;
        newLine.comments = null;
        newLine.currency = null;
        // newLine.customConcept = null;
        newLine.date = line.date;
        // newLine.debtLine = null;
        newLine.documentID = line.documentID;
        newLine.eventID = line.eventID;
        newLine.eventName = line.eventName;
        //newLine.externalTransactionID   
        newLine.info = null;
        // newLine.invitationResponsible = null;
        // newLine.invitationResponsibleName = null;
        // newLine.invitationResponsibleType = 0;
        newLine.legalDocumentID = null;
        newLine.locationCategoryName = null;
        newLine.locationName = null;
        newLine.moneyOtherCurrency = null;
        newLine.payMethod = null;
        newLine.payMethodName = null;
        newLine.payMethodSubtype = null;
        newLine.paymentType = 0;
        //subCashDesk = nil;
        //tags = nil;
        //tax = nil;
        //taxID = nil;
        //taxName = nil;
        // newLine.worker = null;
        newLine.workerName = null;

        ad.managedObjectContext.save();
        DBHelper.saveMainContext();
        return newLine;
    }

    static createBookingFromBooking(booking:Booking):Booking { 
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let b = MIOEntityDescription.insertNewObjectForEntityForName("Booking", ad.managedObjectContext) as Booking;
        
        b.client = booking.client;
        b.clientName = booking.clientName;
        b.clientPhone = booking.clientPhone;
        b.pax = booking.pax;
        b.clientEmail = booking.clientEmail;
        b.source = booking.source;
        b.sourceName = booking.sourceName;
        b.channelID = booking.channelID;
        b.recommendation = booking.recommendation;
        b.recommendationName = booking.recommendationName;

        b.status = BookingStatus.BookingRequest;
        b.clientComments = booking.clientComments;
        
        return b;
    }
    
    static createDistributor(name:string): Distributor {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let distributor = MIOEntityDescription.insertNewObjectForEntityForName("Distributor", ad.managedObjectContext) as Distributor;

        distributor.identifier = new MIOUUID().UUIDString;
        distributor.name = name;
        return distributor;
    }

    static createAgent(name:string, intermediary:Intermediary): Contact {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let agent = MIOEntityDescription.insertNewObjectForEntityForName("Agent", ad.managedObjectContext) as Contact;

        agent.identifier = new MIOUUID().UUIDString;
        agent.name = name;
        agent.legalEntity = intermediary;
        return agent;
    }


    //
    // Calculations for products
    //

    static calculateTotalConsumption(product:Product, quantity:number, quantityMeasure:MeasureUnitType, loss:number, lossMeasure:MeasureUnitType, totalMeasure:MeasureUnitType){
        // Calculate total from the measure unit of total selection        
        let q = quantity;
        let l = loss;

        if (totalMeasure == MeasureUnitType.Container){
            if (quantityMeasure != MeasureUnitType.Container) {
                let factor = MeasureUnits.meassureFactor(quantityMeasure, product.measureUnitType);
                q = (quantity * factor) / product.quantity;            
            }

            if (lossMeasure == MeasureUnitType.Percentage) {
                l = (loss * q) / 100.0;
            }
            else if (lossMeasure != MeasureUnitType.Container) {
                let factor = MeasureUnits.meassureFactor(lossMeasure, product.measureUnitType);
                l = (loss * factor) / product.quantity;
            }
        }
        else {
            if (quantityMeasure == MeasureUnitType.Container) {
                let factor = MeasureUnits.meassureFactor(product.measureUnitType, totalMeasure);
                q = (quantity * factor) * product.quantity;
            }
            else {
                let factor = MeasureUnits.meassureFactor(quantityMeasure, totalMeasure);
                q = quantity * factor;
            }

            if (lossMeasure == MeasureUnitType.Percentage) {
                l = (loss * q) / 100.0;
            }
            else if (lossMeasure == MeasureUnitType.Container) {
                let factor = MeasureUnits.meassureFactor(product.measureUnitType, totalMeasure);
                l = (loss * factor) * product.quantity;
            }
            else {
                let factor = MeasureUnits.meassureFactor(lossMeasure, totalMeasure);
                l = loss * factor;
            }

        }    
        
        return q + l;
    }

    static calculateQuantityConsumption(product:Product, total:number, totalMeasure:MeasureUnitType, loss:number, lossMeasure:MeasureUnitType, quantityMeasure:MeasureUnitType){
        // Calculate quantity from total
        let t = total;
        let l = loss;

        if (quantityMeasure == MeasureUnitType.Container){
            if (totalMeasure != MeasureUnitType.Container) {
                let factor = MeasureUnits.meassureFactor(totalMeasure, product.measureUnitType);
                t = (total * factor) / product.quantity;
            }

            if (lossMeasure != MeasureUnitType.Container) {
                let factor = MeasureUnits.meassureFactor(lossMeasure, product.measureUnitType);
                l = (loss * factor) / product.quantity;
            }
        }
        else {
            if (totalMeasure == MeasureUnitType.Container) {
                let factor = MeasureUnits.meassureFactor(product.measureUnitType, quantityMeasure);
                t = (total * factor) * product.quantity;
            }
            else {
                let factor = MeasureUnits.meassureFactor(totalMeasure, quantityMeasure);
                t = total * factor;
            }

            if (lossMeasure == MeasureUnitType.Container) {
                let factor = MeasureUnits.meassureFactor(product.measureUnitType, quantityMeasure);
                l = (loss * factor) * product.quantity;
            }
            else if (lossMeasure == MeasureUnitType.Percentage) {
                l = (t * loss) / 100.0;
            }
            else {
                let factor = MeasureUnits.meassureFactor(lossMeasure, quantityMeasure);
                l = loss * factor;
            }

        }    
        
        return t - l;
    }

    static additionalProductCostsString(product:Product){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        if (product.isContainer == false) return ad.currencyFormatter.stringFromNumber(product.additionalCosts);

        let cp = product.additionalCosts * product.quantity;
        return ad.currencyFormatter.stringFromNumber(cp);
    }

    static calculateMinumCostFromProduct(product:Product){
        let cost = 0;
        if (product.costType == 0) cost = (product.costLastPrice || 0);
        else if (product.costType == 1) cost = (product.costAveragePrice || 0);
        else if (product.costType == 2) cost = (product.costPrice || 0);        
        
        //cost = product.isContainer == false ? product.costPrice : product.costPrice * product.quantity;
        //let addonCost = product.isContainer == false ? product.additionalCosts : product.additionalCosts * product.quantity;
        cost = cost + (product.additionalCosts || 0);

        return cost;
    }    

    static calculateCostFromProduct(product:Product, recalculateComponents?:boolean){
        let cost = 0;
        if (product.costType == 0) cost = (product.costProductLastPrice || 0);
        else if (product.costType == 1) cost = (product.costProductAveragePrice || 0);
        else if (product.costType == 2) cost = (product.costProductPrice || 0);
        else if (product.costType == 3 && recalculateComponents == true) cost = DBHelper.calculateProductCostFromComponents(product);
        else if (product.costType == 3) cost = (product.costProductComponentsPrice || 0);
        
        //cost = product.isContainer == false ? product.costPrice : product.costPrice * product.quantity;
        //let addonCost = product.isContainer == false ? product.additionalCosts : product.additionalCosts * product.quantity;
        cost = cost + product.additionalProductCosts != null ? product.additionalProductCosts : 0;

        return cost;
    }    

    static calculateCostFromSupplierProduct(product:Product, supplierProduct:SupplierProduct){
        let cost = 0;

        if (supplierProduct.price > 0) cost = supplierProduct.price;
        else if (supplierProduct.computedLastPrice > 0) cost = supplierProduct.computedLastPrice;
        
        cost = cost + (product.additionalProductCosts > 0 ? product.additionalProductCosts : 0);

        return cost;
    }    

    static costFromProductAndSupplier(product:Product, supplier:Supplier){                        
        
        if (product == null) return [null, null];

        let sp:SupplierProduct = null;

        for (let index = 0; index < product.supplierProducts.length; index++){
            let item = product.supplierProducts.objectAtIndex(index) as SupplierProduct;
            if (item.supplier == supplier) {
                sp = item;
                break;
            }
        }

        let cost = 0;
        let discount = null;
        if (sp != null) {
            cost = DBHelper.calculateCostFromSupplierProduct(product, sp);
            discount = sp.discountString;
        }
        if (cost == 0) cost = DBHelper.calculateCostFromProduct(product);
        
        return [cost, discount];
    }

    static costFromProduct(product:Product, supplier?:Supplier){                        
        if (supplier != null) return DBHelper.costFromProductAndSupplier(product, supplier);
        else return DBHelper.calculateCostFromProduct(product);
    }    

    static calculateCostProductString(product:Product){
        let cost = DBHelper.calculateCostFromProduct(product);

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        return ad.currencyFormatter.stringFromNumber(cost);
    }

    static calculateMinumCostFromProductPrice(productPrice:number, product:Product){        
        let price = productPrice;
    
        // TODO: ??
        if (product.isContainer == true) price = productPrice / product.quantity;

        switch(product.measureUnitType){                        
            case MeasureUnitType.VolumeLitre:
            price = price / MeasureUnits.meassureFactor(product.measureUnitType, MeasureUnitType.VolumeCentilitre);
            break;

            case MeasureUnitType.MassKilogram:
            price = price / MeasureUnits.meassureFactor(product.measureUnitType, MeasureUnitType.MassGram);
            break;                   
        }
        
        return price;                    
    }

    static calculateCostFromComponent(product:Product, quantity:number, measure:MeasureUnitType){                        
        // let cost = DBHelper.calculateCostFromProduct(product);        
        let cost = product.computedCost;

        let pm = product.measureUnitType;
        let is_container = product.measureType == MeasureUnitType.Container;            
        let component_factor = quantity * MeasureUnits.meassureFactor(measure, pm);
        
        if ( is_container && measure != -1 ) {            
            component_factor = component_factor / product.quantity;
        }
        
        return cost * component_factor    
    }

    static calculateProductCostFromComponents(product:Product){
        let total = 0;
        for (let index = 0; index < product.components.count; index++){
            let item = product.components.objectAtIndex(index) as Component;
            let cost = DBHelper.calculateCostFromComponent(item.parent, item.totalQuantity, item.totalMeasureType);
            total += cost;
        }
                
        return total;
    }

    // static createProductItemFromProduct(product:Product):ProductListItem{
    //     let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

    //     let productItem = MIOEntityDescription.insertNewObjectForEntityForName("ProductListItem", ad.managedObjectContext) as ProductListItem;
    //     productItem.type = ProductListItemType.Product;
    //     productItem.name = product.name;
    //     productItem.itemID = product.identifier;
    //     //productItem.imageURL = product.imageURL;
    //     productItem.category = product.category;
    //     productItem.product = product;
    //     productItem.price = product.price;

    //     return productItem;
    // }

    static checkProductName(name:string, target, completion){
        DBHelper.queryObjectsWithCompletion("Product", null, MIOPredicate.predicateWithFormat("name == '" + name + "'"), [], this, function(objects){
            if (objects.length > 0) completion.call(target, false);
            else completion.call(target, true);
        });
    }

    static createInputFormat(name:string, quantity:string, product:Product, productID?:string){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let q = ad.numberFormatter.numberFromString(quantity);

        let inputFormat = MIOEntityDescription.insertNewObjectForEntityForName("StockInputFormat", ad.managedObjectContext) as StockInputFormat;
        inputFormat.name = name;
        inputFormat.quantity = q;

        if (product == null) {
            DBHelper.queryObjectsWithCompletion("Product", null, MIOPredicate.predicateWithFormat("identifier == " + productID), [], this, function(products){
                if (products.length == 0) return;
                let p = products[0] as Product; 
                inputFormat.product = p;
                p.addStockInputFormatsObject(inputFormat);
                DBHelper.saveMainContext();
            });
        }
        else {
            inputFormat.product = product;
            product.addStockInputFormatsObject(inputFormat);
        }
        
        return inputFormat;
    }    

    static createProductFormat(product:Product, format:Format):ProductFormat {
        if (product == null) return null;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let pf = MIOEntityDescription.insertNewObjectForEntityForName("ProductFormat", ad.managedObjectContext) as ProductFormat;

        pf.format = format;
        pf.product = product;
        let mu = product.isContainer ? product.containerMeasureType : product.measureType;
        pf.consumptionMeasureType = mu;
        pf.lossMeasureType = mu;
        pf.totalConsumptionMeasureType = mu;
        product.addProductFormatsObject(pf);

        return pf;
    }

    static removeProductFormat(productFormat:ProductFormat){
        if (productFormat == null) return;        
        DBHelper.deleteObjectFromMainContext(productFormat, true);
    }

    static priceFromProductAndSupplier(product:Product, supplier:Supplier):[number, string]{                
        if (supplier == null) return [0, null];

        let array = product.supplierProducts.filterWithPredicate(MIOPredicate.predicateWithFormat("supplier.identifier == " + supplier.identifier));
        if (array.length > 0) {
            let sp = array[0] as SupplierProduct;
            return [sp.price, sp.discountString];
        }

        return [0, null];
    }

    static stockTaxFromProduct(product:Product):Tax {
        if (product.taxBuy != null) return product.taxBuy;
        if (product.tax != null) return product.tax;
        if (product.category != null && product.category.tax != null) return product.category.tax;
        return null;
    }

    // Calculate from product quantity and product price
    static calculateTotalFromStockLine(quantity, price, discountString:string){

        let measurePrice = 0;
        let discount = 0;        
        let total = quantity * price;

        // Calculate discount
        if (discountString) {
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate
            let discountNumber = ad.percentNumberFormatter.numberFromString(discountString);

            if (discountString.hasSuffix("%") == true) {                
                discount = (discountNumber * total) / 100;
            }
            else if (discountString.hasSuffix("%") == false){
                discount = discountNumber;            
            }
        } else {
            discountString = null;
        }

        if (discount != 0) total -= discount;

        return [total, discount];
    }  

    static calculateQuantityFromStockLine(quantity:number, price:number, inputFormat:StockInputFormat, inputType:MeasureUnitType, productMeasureType:MeasureUnitType, productContainerMeasureType:MeasureUnitType, productContainerQuantity:number){
        
        let productPrice = 0;
        let productQuantity = 0;
        // let measureQuantity = 0;
        let factor = 1;

        // Calculate product quantity
        if (inputFormat != null) {
            productQuantity = quantity * inputFormat.quantity;
            productPrice = price / inputFormat.quantity;
        }
        else if (productMeasureType != MeasureUnitType.Container && inputType != productMeasureType) {            
            factor = MeasureUnits.meassureFactor(inputType, productMeasureType);
            productQuantity = quantity * factor;
            productPrice = price * factor;
        }
        else if (productMeasureType == MeasureUnitType.Container && inputType != MeasureUnitType.Container){
            if (inputType != productContainerMeasureType) {
                factor = MeasureUnits.meassureFactor(inputType, productContainerMeasureType);
            }            
            productQuantity = (quantity * factor) / productContainerQuantity;
            productPrice = (price * quantity) / productQuantity;
        }
        else {
            productQuantity = quantity;  
            productPrice = price;
        }

        // Calculate meassure
        // if (productMeasureType == MeasureUnitType.Container) {
        //     measureQuantity = productQuantity * productContainerQuantity;
        // }
        // else {
        //     measureQuantity = productQuantity;
        // }

        // return [productQuantity, measureQuantity, factor];
        return [productQuantity, productPrice];
    }  

    static removeNote(note:StockNote){        
        if (note == null) return;
        DBHelper.deleteObjectFromMainContext(note, true);
        MIONotificationCenter.defaultCenter().postNotification("StockNoteDidDelete", note, null);        
    }

    static createNoteLine(type:StockNoteType, product:Product, tax:Tax, inputFormat:StockInputFormat, lineMeasureType:MeasureUnitType, lineQuantity:number, productQuantity:number, price:number, productPrice:number, total:number, discountFormat:string, discountAmount:number, warehouse:Warehouse, note:StockNote):StockNoteLine {

        if (product == null || lineQuantity == null || note == null) return null;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let entityName = DBHelper.stockNoteLineEntityNameByType(type);
        let line = MIOEntityDescription.insertNewObjectForEntityForName(entityName, ad.managedObjectContext) as StockNoteLine;
        
        line.note = note;
        line.type = type;        
        line.placeID = ad.selectedIdentifier;
        
        line.productName = product.stockName ? product.stockName : product.name;
        line.product = product;
        line.productMeasureType = product.measureType; 
        line.productContainerMeasureType = product.containerMeasureType;
        line.productContainerQuantity = product.containerQuantity;

        let stockTax = tax;
        if (stockTax == null) stockTax = line.stockTax();        
        line.tax = stockTax;
        line.taxName = stockTax != null ? stockTax.name : null;
        line.taxQuantity = stockTax != null ? stockTax.taxQuantity : null;

        if (inputFormat != null) {
            line.inputFormatQuantity = inputFormat.quantity;
            line.inputFormat = inputFormat;
            line.inputFormatName = inputFormat.name;
        }

        line.quantityMeasureType = lineMeasureType;
        line.quantity = lineQuantity;
        line.productQuantity = productQuantity;
        
        line.price = price;
        line.productPrice = productPrice;
        line.baseAmount = total;        
        line.note = note;   
                
        line.orderIndex = Date.now();

        if (discountFormat != null || discountAmount > 0 ) {
            line.discountString = discountFormat;    
            line.discountAmount = discountAmount;
        }
        
        let tax_amount = 0
        if (line.taxQuantity > 0) {
            tax_amount = total * line.taxQuantity;
            line.taxAmount = tax_amount;            
        }
        
        line.totalAmount = tax_amount + total;
        
        line.warehouse =  note.overrideWarehouse ?? (warehouse ?? product.defaultWarehouse);
        line.warehouseName = line.warehouse?.name;

        return line;
    }

    // 
    // StockNote
    //
        
    static stockNoteEntityNameByType(type:StockNoteType):string {
        switch (type) {
            case StockNoteType.BuyOrder: return "BuyOrder";
            case StockNoteType.SupplierOrder: return "SupplierOrder";            
            case StockNoteType.SupplierNote: return "SupplierNote";
            case StockNoteType.DeliveryNote: return "DeliveryNote";
            case StockNoteType.InventoryNote: return "InventoryNote";                                    
            case StockNoteType.MovementNote: return "MovementNote";
            case StockNoteType.CustomOutputNote: return "CustomOutputNote";
            case StockNoteType.CustomInputNote: return "CustomInputNote";
        }
        return null;
    }

    static stockNoteLineEntityNameByType(type:StockNoteType):string {
        switch (type) {
            case StockNoteType.BuyOrder: return "BuyOrderLine";
            case StockNoteType.SupplierOrder: return "SupplierOrderLine";
            case StockNoteType.SupplierNote: return "SupplierNoteLine";
            case StockNoteType.DeliveryNote: return "DeliveryNoteLine";           
            case StockNoteType.InventoryNote: return "InventoryNoteLine";            
            case StockNoteType.MovementNote: return "MovementNoteLine";
            case StockNoteType.CustomOutputNote: return "CustomOutputNoteLine";
            case StockNoteType.CustomInputNote: return "CustomInputNoteLine";
        }
        return null;
    }

    static createStockNote(type:StockNoteType, template:StockTemplate, completion:any) {

        if (template != null) {
            let predicate = MIOPredicate.predicateWithFormat("deletedAt == null AND template.identifier == " + template.identifier);
            //DBHelper.queryObjectsWithCompletion("InventoryTemplateItem", null, predicate, ["product", "product.taxBuy", "product.stockCategory"], this, function(objects){                
            DBHelper.queryObjectsWithCompletion("StockTemplateItem", null, predicate, ["template", "product", "product.taxBuy", "category"], this, function(this:DBHelper, objects){
                DBHelper._createWithNextStockDocumentID(type, objects, completion);
            });
        } else {
            DBHelper._createWithNextStockDocumentID(type, [], completion);
        }        
    }

    private static _createWithNextStockDocumentID(type:StockNoteType, objects:any, completion:any) {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.nextDocumentID("StockNote", type, (documentID:string, documentPrefix:string, documentNumber:number, error:string) => {
            
            if (error != null) {
                AppHelper.showErrorMessage(null, "ERROR", error);
                return;
            }

            let note = DBHelper._createStockNote(type, documentID, documentPrefix, documentNumber, objects);
            completion(note);
        });
    }

    public static nextDocumentNumber(entityName, type:number, completion:any) {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.nextDocumentID(entityName, type, (documentID:string, documentPrefix:string, documentNumber:number, error:string) => {
            
            if (error != null) {
                AppHelper.showErrorMessage(null, "ERROR", error);
                return;
            }
            
            completion(documentID, documentPrefix, documentNumber, error);
        });
    }


    private static _createStockNote(type:StockNoteType, documentID:string, documentPrefix:string, documentNumber:any, templateItems:StockTemplateItem[]):StockNote {

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let entityName = DBHelper.stockNoteEntityNameByType(type);
        let note = MIOEntityDescription.insertNewObjectForEntityForName(entityName, ad.managedObjectContext) as StockNote;
        
        note.placeID = ad.selectedIdentifier;
        //note.appID = "MANAGER";

        let now = MIODateNow();
        note.creationDate = now;
        note.documentDate = now;
        note.stockDate = now;
        note.status = StockNoteStatus.None;
        note.type = type;
        if (documentID != null) note.documentID = documentID;
        if (documentPrefix != null) note.documentPrefix = documentPrefix;

        DBHelper.addTemplateProductsToNote(note, templateItems);
        
        return note;
    }

    private static addTemplateProductsToNote(note:StockNote, objects:StockTemplateItem[]){
        
        if (objects == null || objects.length == 0) return;

        let itemsCache = new MIOSet();

        let templateName = null;
        let categories = [];
        let stockCategories = [];
        for (let index = 0; index < objects.length; index++){
            let item = objects[index];            
            templateName = item.template.name;
            if (item instanceof StockTemplateProductItem) {
                itemsCache.addObject(item.product.identifier.toUpperCase());
                let product = item.product as Product;
                DBHelper.createNoteLineFromProduct(product, note);
                //line.estimatedQuantity = sa.productQuantity;
                //this.selectedNote.addLinesObject(line);
            }
            else if (item instanceof StockTemplateCategoryItem) {
                let category = (item as StockTemplateCategoryItem).category;
                categories.push(category);
            }
            else if (item instanceof StockTemplateStockCategoryItem) {
                let category = (item as StockTemplateStockCategoryItem).stockCategory;
                stockCategories.push(category);
            }

        }
        note.comments = templateName;

        if (categories.length > 0 || stockCategories.length > 0) {
            DBHelper.searchForProductInCategories(note, categories, stockCategories, itemsCache);
        }

        DBHelper.saveMainContext();
    }

    private static searchForProductInCategories(note: StockNote, categories:ProductCategory[], stockCategories:StockCategory[], cache:MIOSet){

        if (categories.length > 0) DBHelper.queryObjectsWithCompletion("ProductCategory", null, MIOPredicate.predicateWithFormat("deletedAt = null"), [], this, function(){
            DBHelper.filterCategories(note, categories, [], cache);
        });

        if (stockCategories.length > 0) DBHelper.queryObjectsWithCompletion("StockCategory", null, MIOPredicate.predicateWithFormat("deletedAt = null"), [], this, function(){
            DBHelper.filterCategories(note, [], stockCategories, cache);
        });
    }

    private static filterCategories(note: StockNote, categories:ProductCategory[], stockCategories:StockCategory[], cache:MIOSet){
        let filterCategories = [];

        for (let index = 0; index < categories.length; index++){
            let c = categories[index];
            let scs = DBHelper.followSubCategories(c);
            for (let j = 0; j < scs.length; j++) {
                filterCategories.push(scs[j]);
            }
        }

        for (let index = 0; index < stockCategories.length; index++){
            let c = stockCategories[index];
            let scs = DBHelper.followSubCategories(c);
            for (let j = 0; j < scs.length; j++) {
                filterCategories.push(scs[j]);
            }
        }

        let categoryIDs = [];
        if (categories?.length > 0) categoryIDs = filterCategories.map( category => "category.identifier = " + category.identifier.toUpperCase() );
        if (stockCategories?.length > 0) categoryIDs = filterCategories.map( category => "stockCategory.identifier = " + category.identifier.toUpperCase() );
        // for (let index = 0; index < filterCategories.length; index++) {
        //     let c = filterCategories[index];
        //     categoryIDs.push(c.identifier);
        // }
        let predicateFormat = "isEnableForStock = true and deletedAt = null and (" + categoryIDs.join(" or ") + ")";
        DBHelper.queryObjectsWithCompletion("Product", null, MIOPredicate.predicateWithFormat(predicateFormat), ["category", "stockCategory", "supplierProducts.supplier"], this, function(products:Product[]){
            DBHelper.addStockProductLines(note, products, cache);
        });
    }

    private static followSubCategories(category:ProductCategory | StockCategory) : ProductCategory[] {
        
        let categories = [];
        categories.push(category);
        for (let index = 0; index < category.subCategories.count; index++) {
            let c = category.subCategories.objectAtIndex(index);
            let scs = this.followSubCategories(c);
            for (let i = 0; i < scs.length; i++) {
                let sc = scs[i];
                categories.push(sc);
            }
        }
        return categories;
    }

    private static addStockProductLines(note: StockNote, products:Product[], cache:MIOSet) {
        for (let index = 0; index < products.length; index++) {
            let p = products[index];
            if (cache.containsObject(p.identifier.toUpperCase())) continue;
            DBHelper.createNoteLineFromProduct(p, note);
        }
        DBHelper.saveMainContext();
    }
    

    static getLastPruchaseSupplierProduct(product:Product) : SupplierProduct {
        if (product.supplierProducts.count > 0) {

            let ps = product.supplierProducts.sortedArrayUsingDescriptors([MIOSortDescriptor.sortDescriptorWithKey("updatedAt", false)]);
            let sp = ps[0]; // product.supplierProducts.objectAtIndex(0) as SupplierProduct;

            return sp;
        }

        return null;
    }

    static createNoteLineFromProduct(product:Product, note:StockNote, warehouse?:Warehouse) : StockNoteLine {
        
        let line = DBHelper.createNoteLine(note.type, product, product.taxBuy, null, product.measureType, 0, 0, 0, 0, 0, null, 0, warehouse, note);

        switch(note.type) {
            case StockNoteType.BuyOrder:
            let sp = DBHelper.getLastPruchaseSupplierProduct(product);
            if (sp != null) {
                line.legalEntity = sp.supplier;
                line.legalEntityName = sp.supplier.name;
                line.price = sp.price;
                line.productPrice = sp.price;
                line.discountString = sp.discountString;
            }
            break;
        }        

        return line;
    }


    //
    // Buy order
    //

    static createBuyOrderStockNoteLine(product:Product, inputFormat:StockInputFormat, measureUnit:MeasureUnitType, quantity:number, productQuantity:number, price:number, productPrice:number, total:number, discountFormat:string, discountAmount:number, supplier:Supplier, note:StockNote):StockNoteLine {
        let line = this.createNoteLine(StockNoteType.BuyOrder, product, null, inputFormat, measureUnit, quantity, productQuantity, price, productPrice, total, discountFormat, discountAmount, null, note);
        line.legalEntity = supplier;
        line.legalEntityName = supplier ? supplier.name : null;
        return line;
    }

    //
    // Supplier order
    //

    static createSupplierOrder(supplier:Supplier, template:StockTemplate, completion:any) {
        DBHelper.createStockNote(StockNoteType.SupplierOrder, template, (note:SupplierOrder) => {
            note.destinationEntity = supplier;
            note.destinationName = supplier.name;
            completion(note);
        });        
    }

    static createSupplierOrderLine(product:Product, inputFormat:StockInputFormat, measureUnit:MeasureUnitType, quantity:number, productQuantity:number, price:number, productPrice:number, total:number, discountFormat:string, discountAmount:number, note:StockNote):StockNoteLine {
        return this.createNoteLine(StockNoteType.SupplierOrder, product, null, inputFormat, measureUnit, quantity, productQuantity, price, productPrice, total, discountFormat, discountAmount, null, note);
    }

    //
    // Supplier note
    //

    static createSupplierNote(supplier:Supplier, template:StockTemplate, completion:any) {
        DBHelper.createStockNote(StockNoteType.SupplierNote, template, (note:SupplierNote) => {
            note.originEntity = supplier;
            note.originName = supplier.name;    
            completion(note);
        });                
    }

    static createSupplierNoteLine(product:Product, tax:Tax, inputFormat:StockInputFormat, measureUnit:MeasureUnitType, quantity:number, productQuantity:number, price:number, productPrice:number, total:number, discountFormat:string, discountAmount:number, warehouse:Warehouse, note:StockNote):StockNoteLine {
        let line =  this.createNoteLine(StockNoteType.SupplierNote, product, tax, inputFormat, measureUnit, quantity, productQuantity, price, productPrice, total, discountFormat, discountAmount, warehouse, note);
        line.warehouse = warehouse;
        line.warehouseName = warehouse?.name;
        return line;
    }

    //
    // Inventory notes
    //
    static createInventoryNote(destination:Warehouse, template:StockTemplate, completion:any) {
        DBHelper.createStockNote(StockNoteType.InventoryNote,template, (note:StockNote) => {
            note.destinationWarehouse = destination;
            note.destinationName = destination.name;
            completion(note);
        });        
    }

    static createInventoryNoteLine(product:Product, inputFormat:StockInputFormat, measureUnit:MeasureUnitType, quantity:number, productQuantity:number, containerQuantity:number, warehouse:Warehouse, note:StockNote):StockNoteLine {
        return this.createNoteLine(StockNoteType.InventoryNote, product, null, inputFormat, measureUnit, quantity, productQuantity, 0, 0, 0, null, 0, warehouse, note);
    }

    //
    // Output notes
    //

    static createOutputNote(origin:Warehouse, destination:StockCustomConcept, template:StockTemplate, completion:any){
        DBHelper.createStockNote(StockNoteType.CustomOutputNote, template, (note:StockNote) => {
            note.originWarehouse = origin;
            note.originName = origin.name;
            note.destinationName = destination.name;
            note.destinationConcept = destination;
            completion(note);
        });            
    }

    static createOutputNoteLine(product:Product, inputFormat:StockInputFormat, measureUnit:MeasureUnitType, quantity, productQuantity, price:number, productPrice:number, total:number, note:StockNote):StockNoteLine {
        return this.createNoteLine(StockNoteType.CustomOutputNote, product, null, inputFormat, measureUnit, quantity, productQuantity, price, productPrice, total, null, 0, null, note);
    }

    //
    // Output notes
    //

    static createInputNote(origin:StockCustomConcept, destination:Warehouse, template:StockTemplate, completion:any){
        DBHelper.createStockNote(StockNoteType.CustomInputNote, template, (note:StockNote) => {
            note.originConcept = origin;
            note.originName = origin.name;
            note.destinationName = destination.name;
            note.destinationWarehouse = destination;
            completion(note);
        });            
    }

    static createInputNoteLine(product:Product, inputFormat:StockInputFormat, measureUnit:MeasureUnitType, quantity:number, productQuantity:number, price:number, productPrice:number, total:number, warehouse:Warehouse, note:StockNote):StockNoteLine {
        return this.createNoteLine(StockNoteType.CustomInputNote, product, null, inputFormat, measureUnit, quantity, productQuantity, price, productPrice, total, null, 0, warehouse, note);
    }

    //
    // Movements notes
    //

    static createMovementNote(origin:Warehouse, destination:Warehouse, completion:any) {
        DBHelper.createStockNote(StockNoteType.MovementNote, null, (note:StockNote) => {        
            note.originWarehouse = origin;
            note.originName = origin.name;
            note.destinationWarehouse = destination;
            note.destinationName = destination.name;
            completion(note);
        });        
    }

    static createMovementNoteLine(product:Product, inputFormat:StockInputFormat, measureUnit:MeasureUnitType, quantity:number, productQuantity: number, note:StockNote):StockNoteLine {
        return this.createNoteLine(StockNoteType.MovementNote, product, null, inputFormat, measureUnit, quantity, productQuantity, 0, 0, 0, null, 0, null, note);
    }

    //
    // Delivery note
    //

    static createDeliveryNote(legalEntity:LegalEntity, completion:any){
        DBHelper.createStockNote(StockNoteType.DeliveryNote, null, (note) => {
            note.destinationEntity = legalEntity;
            note.destinationName = legalEntity.name;
            completion(note);
        });            
    }

    static createDeliveryNoteLine(product:Product, tax:Tax, inputFormat:StockInputFormat, measureUnit:MeasureUnitType, quantity:number, productQuantity:number, price:number, productPrice:number, total:number, discountFormat:string, discountAmount:number, warehouse:Warehouse, note:StockNote):StockNoteLine {
        let line =  this.createNoteLine(StockNoteType.DeliveryNote, product, tax, inputFormat, measureUnit, quantity, productQuantity, price, productPrice, total, discountFormat, discountAmount, warehouse, note);
        line.warehouse = warehouse;
        line.warehouseName = warehouse?.name;
        return line;
    }

    //
    // Low stock order note line
    //
    
    static createLowStockNoteLine(product:Product, inputFormat:StockInputFormat, measureUnit:MeasureUnitType, quantity, productQuantity, supplier:Supplier, note:StockNote):StockNoteLine {
        let line = this.createNoteLine(StockNoteType.LowStockOrder, product, null, inputFormat, measureUnit, quantity, productQuantity, 0, 0, 0, null, 0, null, note);
        line.legalEntity = supplier;
        line.legalEntityName = supplier ? supplier.name : null;
        return line;
    }


    static createWarehouseOrderLine(product:Product, inputFormat:StockInputFormat, measureUnit:MeasureUnitType, quantity:number, productQuantity:number, warehouse:Warehouse, note:StockNote):StockNoteLine {
        let line =  this.createNoteLine(StockNoteType.WarehouseOrder, product, null, inputFormat, measureUnit, quantity, productQuantity, 0, 0, 0, null, 0, warehouse, note);
        line.warehouse = warehouse;
        line.warehouseName = warehouse?.name;
        return line;
    }


    // 
    // Others
    //

    static createSupplierProduct(supplier:Supplier, product:Product, price:number, productPrice:number, reference:string, discount:string):SupplierProduct {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let sp = MIOEntityDescription.insertNewObjectForEntityForName("SupplierProduct", ad.managedObjectContext) as SupplierProduct;        
                
        sp.supplier = supplier;
        sp.product = product;        
        sp.supplierReference = reference;
        sp.price = price;
        sp.productPrice = productPrice;
        sp.discountString = discount;

        return sp;
    }


    static removeSupplierProduct(item:SupplierProduct, save?){
        let moc:MIOManagedObjectContext = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        moc.deleteObject(item);
        if (save == true) moc.save();
    }


    //
    // Worker
    //

    static createWorkSession(beginDate:Date, endDate:Date, worker:Employee):WorkSession {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            
        let ws = MIOEntityDescription.insertNewObjectForEntityForName("WorkSession", ad.managedObjectContext) as WorkSession;
        ws.beginDate = beginDate;
        ws.endDate = endDate;
        ws.worker = worker;

        ws.placeID = ad.selectedIdentifier;

        return ws;
    }


}