
class BankDetailViewController extends BaseTableViewController
{    
    static newInstance() : BankDetailViewController {
        let vc = new BankDetailViewController("bank-detail-view");
        vc.initWithResource("layout/bankaccount/BankDetailView.html");
        return vc;
    }

    private titleLabel:MUILabel = null;
    private optionsButton:MUIButton = null;
    private addButton:MUIButton = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, this.addAccountAction);

        this.optionsButton = MUIOutlet(this, "action-btn", "MUIButton");
        this.optionsButton.setAction(this, this.showActions);

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;          
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _bank:Bank = null;
    set item(item:Bank) {
        this._bank = item;        
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._bank == null) return;

        this.titleLabel.text = this._bank.name;

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let cell = tableView.dequeueReusableCellWithIdentifier('BankAccountCell') as BankAccountCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as BankAccount;
        
        cell.item = item;

        return cell;            
    }
    
    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){        
        this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath);
        this.showSelectedItemViewController();
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('name', true)];
        let predicateFormat = 'bank.identifier == ' + this._bank.identifier;
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("BankAccount", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["info"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    private addAccountAction() {
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("ADD BANK ACCOUNT", "ADD BANK ACCOUNT"), MIOLocalizeString("ADD BANK ACCOUNT", "ADD BANK ACCOUNT"), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
            textField.placeholderText = MIOLocalizeString("ACCOUNT NUMBER", "ACCOUNT NUMBER");
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
            textField.placeholderText = MIOLocalizeString("NAME", "NAME");
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK", "OK"), MUIAlertActionStyle.Default, this, function(this:BankDetailViewController) {
            let number = avc.textFields[0].text;
            let name = avc.textFields[1].text;
            this.addAccount(number, name);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private addAccount(number:string, name:string){
        if (number == null) { AppHelper.showErrorMessage(this, MIOLocalizeString("BANK ERROR CREATION","BANK ERROR CREATION"), MIOLocalizeString("THE NUMBER IS NOT OPTIONAL","THE NUMBER IS NOT OPTIONAL")); return;}

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let acc = MIOEntityDescription.insertNewObjectForEntityForName("BankAccount", ad.managedObjectContext) as BankAccount;
        acc.identifier = (new MIOUUID()).UUIDString;        
        acc.bank = this._bank;

        let info = MIOEntityDescription.insertNewObjectForEntityForName("BankAccountInfo", ad.managedObjectContext) as BankAccountInfo;
        info.title = name;
        info.number = number;
        acc.info = info;

        ad.managedObjectContext.save();
    }

    private showSelectedItemViewController(){
        let vc = BankAccountDetailViewController.newInstance();

        vc.item = this.selectedItem;
        this.navigationController.pushViewController(vc, true);
    }

    private showActions(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("BANK OPTIONS", "BANK OPTIONS"), MIOLocalizeString("BANK OPTIONS", "BANK OPTIONS"), MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("LINK WITH BANK", "LINK WITH BANK"), MUIAlertActionStyle.Default, this, function(this:BankDetailViewController) {
            this.bankLogin();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);    
    }
    
    private bankLogin(){

    }

    /*
    private login(){
        if (this.bankType == 1) this.loginBBVA();
    }
    
    private token = null;
    private tokenType = null;
    private accessToken = null;
    private loginBBVA(){
        let request = new MWSJSONRequest();
        request.initWithURL(MIOURL.urlWithString("https://connect.bbva.com/token?grant_type=client_credentials"), null, "POST");
        request.setHeaderValue("Basic YXBwLmJidmEuZGx3ZWJtYW5hZ2VyOjF5eTZSYzJNKm9mOW1oVHlHdGtoNTJRRzB4OHN4OFRhMzZ2Q3hFQVpmbFNJS2ZZTXdBNnNZd2lMa1lsNDBITnM=", "Authorization");
        request.setHeaderValue("application/json", "Content-Type");
        

        request.execute(this, function (code, json) {
            this.token = json["access_token"];
            this.tokenType = json["token_type"];
            this.accessToken = this.tokenType + " " + this.token;
            MIOLog("LOGIN OK");
        });
    }

    private addAccount(){        

        let request = new MWSJSONRequest();
        request.initWithURL(MIOURL.urlWithString("https://apis.bbva.com/business-accounts-sbx/v1"), this.getUserInfo(), "POST");
        request.setHeaderValue(this.accessToken, "Authorization");
        request.setHeaderValue(MIOUUID.UUID().UUIDString, "X-Unique-Transaction-ID");        
        request.setHeaderValue("12.03.1.3", "X-Customer-IP");        

        request.execute(this, function (code, json) {
            MIOLog(json);
        });
    }

    private getUserInfo(){
        let userInfo = {
            "first_name": "Paul",
            "middle_name": "Antonio",
            "last_name": "Smith",
            "ssn": "5433837071287255",
            "dob": "1980-02-15",
            "contact": [
              {
                "type": "phone",
                "value": "+14153214567"
              },
              {
                "type": "email",
                "value": "paulsmith@gmail.com"
              }
            ],
            "citizenship_status": "us_citizen",
            "citizenship_country": "USA",
            "occupation": "finance_accounting_tax",
            "income": [
              "SALARY",
              "INVESTMENTS",
              "INHERITANCE",
              "PROPERTY_SALE",
              "DIVORCE_SETTLEMENT",
              "LIFE_INSURANCE",
              "OTHER"
            ],
            "expected_activity": [
              "CASH",
              "CHECK",
              "DOMESTIC_ACH",
              "DOMESTIC_WIRE_TRANSFER",
              "INTERNATIONAL_ACH",
              "INTERNATIONAL_WIRE_TRANSFER"
            ],
            "address": [
              {
                "type": "LEGAL",
                "line1": "201 mission st",
                "city": "san francisco",
                "state": "CA",
                "zip_code": "94104"
              }
            ],
            "identification": [
              {
                "document": "passport",
                "issuing_state": "ca",
                "number": "123456789",
                "issuing_country": "USA",
                "issued_date": "2015-01-01",
                "expiration_date": "2020-01-01"
              }
            ],
            "pep": {
              "association": "no"
            }

        }

        return userInfo;
    }
    */
}