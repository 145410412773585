class PaymentEntityViewController extends MUIViewController
{
    static newInstance() : PaymentEntityViewController
    {
        let vc = new PaymentEntityViewController("payment-entity-view");
        vc.initWithResource("layout/paymententity/PaymentEntityView.html");
        return vc;
    }

    private addButton:MUIButton = null;
    private saveButton:MUIButton = null;
    private tableView:MUITableView = null;

    viewDidLoad() {
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function(this:PaymentEntityViewController){
            this.showAddPaymentEntityViewController();
        });

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        });
        
        this.tableView = MUIOutlet(this, 'table-view', 'UITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        
    }

    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private updateUI()
    {
        if (this.viewIsLoaded == false) return;

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableview:UITableView)
    {
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section)
    {
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath) 
    {   
        let cell = null;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as PaymentEntity;

        if (item instanceof ProxyPaymentEntity) cell = tableview.dequeueReusableCellWithIdentifier('ProxyPaymentEntityCell') as PaymentEntityCell;
        else if (item instanceof CashOnDeliveryPaymentEntity) cell = tableview.dequeueReusableCellWithIdentifier('CashPaymentEntityCell') as PaymentEntityCell;
        else if (item instanceof AdyenPaymentEntity) cell = tableview.dequeueReusableCellWithIdentifier('AdyenPaymentEntityCell') as AdyenPaymentEntityCell;
        // else if (item.paymentType == PaymentEntityType.DualLink) cell = tableview.dequeueReusableCellWithIdentifier('DLPaymentEntityCell') as DLPaymentEntityCell;        
        // else if (item.paymentType == PaymentEntityType.Redsys) cell = tableview.dequeueReusableCellWithIdentifier('RedsysPaymentEntityCell') as RedsysPaymentEntityCell;
        // else if (item.paymentType == PaymentEntityType.Stripe) cell = tableview.dequeueReusableCellWithIdentifier('StripePaymentEntityCell') as PaymentEntityCell;
        // else if (item.paymentType == PaymentEntityType.PayPal) cell = tableview.dequeueReusableCellWithIdentifier('PayPalPaymentEntityCell') as PaymentEntityCell;
        // else if (item instanceof AdyenPaymentEntity) cell = tableview.dequeueReusableCellWithIdentifier('AdyenPaymentEntityCell') as AdyenPaymentEntityCell;

        cell.item = item;
        return cell;
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {        
        return UITableViewCellEditingStyle.Delete;
    }
    
    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as PaymentEntity;    

        if (editingStyle == UITableViewCellEditingStyle.Delete) {
            DBHelper.deleteObjectFromMainContext(item, true);
        }
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("PaymentEntity", sortDescriptors, null);
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller)
    {
        this.tableView.reloadData();
    }

    private showAddPaymentEntityViewController() {

        const types = PaymentEntity.supportedTypesForCurrentRegion();
        if (types.length == 0) {
            AppHelper.showErrorMessage(this, MIOLocalizeString("PAYMENT ENTITY ERROR", "PAYMENT ENTITY ERROR") , MIOLocalizeString("THERE'S NO AVAILABLE PAYMENT TYPE ENTITY FOR THIS REGION", "THERE'S NO AVAILABLE PAYMENT TYPE ENTITY FOR THIS REGION"));
            return;
        }

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("NEW PAYMENT ENTITY", "NEW PAYMENT ENTITY"), MIOLocalizeString("CHOOSE A NAME", "CHOOSE A NAME"), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
            textField.placeholderText = MIOLocalizeString("NAME", "NAME");
        });

        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox) {            

            for (let t of types) {
                comboBox.addItem(PaymentEntity.typeStringForType(t), t);
            }
            
            comboBox.selectItem(0);
        });

        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox) {            
           
            comboBox.addItem( MIOLocalizeString("TEST", "TEST"), 0);
            comboBox.addItem( MIOLocalizeString("LIVE", "LIVE"), 1);
            
            comboBox.selectItem(0);
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("ADD", "ADD"), MUIAlertActionStyle.Default, this, function() {
            let name = avc.textFields[0].text;
            let type = avc.comboBoxes[0].getSelectedItem();
            let env_value = avc.comboBoxes[1].getSelectedItem();

            if (name.length == 0) {
                AppHelper.showErrorMessage(this, MIOLocalizeString("UNABLE TO CREATE", "UNABLE TO CREATE"), MIOLocalizeString("YOU MUST SPECIFY A NAME", "YOU MUST SPECIFY A NAME"));
                return;
            }                        

            DBHelper.createPaymentEntity(name, parseInt(type), env_value == 1 ? "live" : "test", true);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }
}